<template>		
	<div class="">
		<div :class="[getStatus(pedido).style]" class="rounded-full text-center w-auto py-1 px-4">
			<p class="whitespace-nowrap">{{ getStatus(pedido).text }}</p>
		</div>
	</div>
</template>

<script>
	import { utils } from '../utils/utils.js'
	export default {
		props: ['pedido', 'user'],

		setup(props) {		

			function getStatus(pedido) {				
				if (pedido.cancelado == true ) {
					return {
						style: "border border-gray-300 text-gray-300",
						text: "Cancelado"
					}
				} else if (utils.isPendiente(props.user, pedido)) {
					return {
						style: "bg-yellow-500 text-white",
						text: "Pendiente"
					}
				} else {
					return {
						style: "bg-primary text-white",
						text: "Completo"
					}
				}
			}
			return {
				getStatus
			}
		}
	}
</script>