<template>
	<div class="flex flex-col h-full">		
		<div class="flex-auto overflow-scroll">			
			<h3 class="text-2xl font-bold pt-4 px-4 flex content-center">  
				<div @click="utils.goBack(router, `/proveedor/${route.params.proveedor_id}/producto/${producto.id}`)" class="pr-2 flex items-center cursor-pointer">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
						<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
					</svg>
				</div>

				{{route.params.producto_precio_id ? "Editar precio" : "Agregar precio"}}				
			</h3>

			<div v-if="productoPrecio" class="w-full p-4">
				<div class="w-full">
					<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
						Nombre
					</label>
					<input class="appearance-none border order-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500  w-full mb-2" id="nombre" type="text" placeholder="Nombre" v-model="productoPrecio.nombre">						
				</div>
				<div class="mt-4 w-full">
					<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
						Precio
					</label>
					<input class="appearance-none border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500 w-full mb-2" id="nombre" type="number" placeholder="0" v-model="productoPrecio.precio">				
				</div>
			</div>
		</div>

		<div class="p-4 flex-none border-t border-t-gray-300">
			<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4" @click="upsertProductoPrecio()">
				{{productoPrecio && productoPrecio.id ? "Actualizar precio" : "Crear precio"}}	
			</button>						
		</div>

	</div>
</template>

<script>
	import { useRoute, useRouter } from 'vue-router' 
	import { onMounted, ref } from "vue"
	import { useToast } from 'vue-toast-notification';
	import { useLoading } from 'vue-loading-overlay'
	import { utils } from './utils/utils.js'

	export default {
		name: 'Order',
		setup() {
			const route = useRoute()
			const router = useRouter()	
			const producto = ref({})	
			const productoPrecio = ref(null)

			const loading = useLoading({
				color: "#428b74",
				loader: "spinner"
			});

			async function getProductoPrecio() {		
				if (!route.params.producto_precio_id) {
					return {
						producto: route.params.producto_id
					}
				}		

				return await utils.getProductoPrecio(route.params.producto_precio_id)				
			}

			async function upsertProductoPrecio() {
				if (!productoPrecio.value.nombre || !productoPrecio.value.precio) {
					return useToast().warning('Asegúrate que todos los campos están llenos', { position: "top-right", duration: 5000  });
				}			

				if (productoPrecio.value.precio == 0) {
					return useToast().warning('El producto debe de tener un precio arriba de $0', { position: "top-right", duration: 5000  });
				}	

				let loader = loading.show()

				utils.updateProductoPrecio(productoPrecio.value)
				.then( data => {
					console.log(data)
					loader.hide()
					useToast().success(productoPrecio.value.id ? 'Precio actualizado' : 'Precio creado', { position: "top-right", duration: 5000 });

					if (!productoPrecio.value.id) {
						router.push(`/proveedor/${producto.value.proveedor}/producto/${producto.value.id}`)			
					}
					productoPrecio.value = data
				})
				.catch( () => {
					loader.hide()
					useToast().error('Hubo un error. Favor de intentarlo otra vez.', { position: "top-right", duration: 5000 });
				})			
			}

			onMounted( async () => {
				getProductoPrecio()
				.then( data => {
					productoPrecio.value = data
				})					
				
				utils.getProducto(route.params.producto_id)
				.then( data => {
					producto.value = data
				})
			})		

			return {   
				productoPrecio,
				route,
				router,
				upsertProductoPrecio,
				producto,
				utils
			}
		},
	}
</script> 