<template>
	<div class="flex flex-col h-full">
		<div class="flex-auto overflow-scroll">
			
			
			<h3 class="text-2xl font-bold pt-4 px-4 flex content-center">  
				<div @click="utils.goBack(router, `/proveedor/${route.params.proveedor_id}/direcciones`)" class="pr-2 flex items-center cursor-pointer">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
						<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
					</svg>
				</div>	
				<div>				

					<div>      		
						{{ direccion.id ? "Actualizar dirección" : "Crear dirección"}}
					</div>

				</div>
			</h3>

			<div class="w-full p-4">
				<div class="mb-4">
					<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
						Nombre
					</label>
					<input class="appearance-none border border-gray-300 focus:border-gray-500 border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2" id="nombre" type="text" placeholder="Nombre" v-model="direccion.nombre">
				</div>	

				<div class="mb-4">
					<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
						Dirección de Entrega
					</label>
					<input class="appearance-none border border-gray-300 focus:border-gray-500 border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2" id="nombre" type="text" placeholder="Exterior" v-model="direccion.direccion_exterior">
					<input class="appearance-none border border-gray-300 focus:border-gray-500 border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2" id="nombre" type="text" placeholder="Calle" v-model="direccion.direccion_calle">
					<input class="appearance-none border border-gray-300 focus:border-gray-500 border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2" id="nombre" type="text" placeholder="Interior/Local" v-model="direccion.direccion_interior">	
					<input class="appearance-none border border-gray-300 focus:border-gray-500 border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2" id="nombre" type="text" placeholder="Colonia" v-model="direccion.direccion_colonia">	
					<input class="appearance-none border border-gray-300 focus:border-gray-500 border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2" id="nombre" type="text" placeholder="Código postal" v-model="direccion.direccion_codigo">	

					<select v-model="direccion.direccion_estado" id="estados" class="border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
						<option selected disabled value="">Escoger un estado</option>
						<option v-for="estado in estados" v-bind:key="estado" :value="estado">{{estado}}</option>			
					</select>

				</div>

				<div class="mb-4">
					<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
						Contacto
					</label>
					<input class="appearance-none border border-gray-300 focus:border-gray-500 border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2" id="nombre" type="text" placeholder="Nombre" v-model="direccion.contacto_nombre">
					<input class="appearance-none border border-gray-300 focus:border-gray-500 border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2" id="nombre" type="tel" placeholder="Teléfono" v-model="direccion.contacto_telefono">
				</div>	

				<div class="">
					<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
						Horario de Entrega
					</label>			
					<div v-for="day in days" v-bind:key="day">					
						<div class="grid grid-cols-9 gap-2 flex items-center h-12">					
							<p class="col-span-2">{{day}}</p>
							<input id="default-checkbox" type="checkbox" v-model="direccion.horario[day].open" class="col-span-1 w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">						

							<select v-if="direccion.horario[day].open == true" v-model="direccion.horario[day].start" class="h-10 col-span-3 border border-gray-300 focus:border-gray-500 border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline whitespace-nowrap">			
								<option selected disabled value="">Escoger horario</option>
								<option value="10:00">10:00 AM</option>			
								<option value="11:00">11:00 AM</option>			
								<option value="12:00">12:00 PM</option>			
								<option value="13:00">1:00 PM</option>			
								<option value="14:00">2:00 PM</option>			
								<option value="15:00">3:00 PM</option>			
								<option value="16:00">4:00 PM</option>											
								<option value="17:00">5:00 PM</option>											
								<option value="18:00">6:00 PM</option>											
								<option value="19:00">7:00 PM</option>											
								<option value="20:00">8:00 PM</option>											
							</select>

							<select v-if="direccion.horario[day].open == true" v-model="direccion.horario[day].end" class=" h-10 col-span-3 border border-gray-300 focus:border-gray-500 border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline whitespace-nowrap">			
								<option selected disabled value="">Escoger horario</option>
								<option value="10:00">10:00 AM</option>			
								<option value="11:00">11:00 AM</option>			
								<option value="12:00">12:00 PM</option>			
								<option value="13:00">1:00 PM</option>			
								<option value="14:00">2:00 PM</option>			
								<option value="15:00">3:00 PM</option>			
								<option value="16:00">4:00 PM</option>	
								<option value="17:00">5:00 PM</option>											
								<option value="18:00">6:00 PM</option>											
								<option value="19:00">7:00 PM</option>											
								<option value="20:00">8:00 PM</option>												
							</select>						
						</div>					
					</div>							
				</div>		
			</div>
		</div>


		<div v-if="proveedor && proveedor.id" class="p-4 flex-none border-t border-t-gray-300">
			<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4" @click="execute">
				{{ direccion.id ? "Actualizar dirección" : "Crear dirección"}}
			</button>
		</div>
		
	</div>
</template>

<script>
	import { supabase } from "../supabase"
	import { useRoute, useRouter } from 'vue-router'
	import { onMounted, ref } from "vue"
	import { useToast } from 'vue-toast-notification';
	import { useLoading } from 'vue-loading-overlay'
	import { utils } from "./utils/utils.js"	

	const loading = useLoading({
		color: "#428b74",
		loader: "spinner"
	});

	export default {
		name: 'Order',
		setup() {
			const route = useRoute()
			const router = useRouter()						
			const proveedor = ref(null)					

			const estados = ref(["Aguascalientes",
				"Baja California",
				"Baja California Sur",
				"Campeche",
				"Chiapas",
				"Chihuahua",
				"Ciudad de México",
				"Coahuila",
				"Colima",
				"Durango",
				"Estado de México",
				"Guanajuato",
				"Guerrero",
				"Hidalgo",
				"Jalisco",
				"Michoacán",
				"Morelos",
				"Nayarit",
				"Nuevo León",
				"Oaxaca",
				"Puebla",
				"Querétaro",
				"Quintana Roo",
				"San Luis Potosí",
				"Sinaloa",
				"Sonora",
				"Tabasco",
				"Tamaulipas",
				"Tlaxcala",
				"Veracruz",
				"Yucatán",
				"Zacatecas"])

			const direccion = ref({ 
				horario: {
					lunes: { open: true, start: "", end: "" },
					martes: { open: true, start: "", end: "" },
					miercoles: { open: true, start: "", end: "" },
					jueves: { open: true, start: "", end: "" },
					viernes: { open: true, start: "", end: "" },
					sabado: { open: false },
					domingo: { open: false }
				},
				direccion_estado: "",
				proveedor: route.params.proveedor_id
			})

			const days = ref([
				"lunes",
				"martes",
				"miercoles",
				"jueves",
				"viernes",
				"sabado",
				"domingo",
				])

			async function getProveedor() {
				let { data, error } = await supabase
				.from("proveedores")
				.select('id, nombre, funciones')          
				.eq("id", route.params.proveedor_id)
				.single()

				if (data) {   
					proveedor.value = data
					return data
				} else if (error) {  
					alert(error)     
				}   

				return
			}					

			function hasRequiredValues() {
				let missingDays = Object.keys(direccion.value.horario).map( (key) => {
					console.log("direccion.value.horario[key]", direccion.value.horario[key].open)
					if (direccion.value.horario[key].open != false) {
						if (!direccion.value.horario[key].start || !direccion.value.horario[key].end) {
							return 1
						} 

						return 0
					}

					return 0
				}).reduce((a, b) => a + b)

				console.log("missingDays", missingDays)

				if (missingDays > 0) {
					useToast().warning('Asegúrate que el horario está completo', { position: "top-right" });
					return false
				}

				// let total = productos.value.map ( p => { return p.cantidad || 0}).reduce((a, b) => a + b)         

				let keys = [	
					"nombre",
					"direccion_exterior",
					"direccion_calle",
					"direccion_colonia",
					"direccion_codigo",
					"direccion_estado",
					"contacto_nombre",
					"contacto_telefono",				
					"horario"
					]

				let values = 0

				keys.forEach( key => {
					console.log(key, direccion.value[key])
					if (direccion.value[key]) {						
						values++			
					}
				})								

				if (values != keys.length) {
					useToast().warning('Asegúrate que todos los datos están', { position: "top-right" });
					return false
				} else {
					return true
				}
			}

			async function execute() {				
				if (!hasRequiredValues()) {					
					return 
				}

				var loader = loading.show()	

				utils.upsertDireccion(direccion.value)
				.then( d => {
					loader.hide()
					useToast().success(direccion.value.id ? 'Dirección actualizada' : 'Dirección creada', { position: "top-right" });
					direccion.value = d
				})
				.catch( e => {
					loader.hide()
					console.log(e)
					useToast().warning('Hubo un error. Favor de intentarlo otra vez.', { position: "top-right" });
				})
			}

			onMounted(() => {			
				getProveedor()				
				if (route.params.direccion_id) {
					utils.getDireccion(route.params.direccion_id)			
					.then( d => {
						direccion.value = d
					}) 
				}			
			})

			return {      
				route,				
				proveedor,
				execute,
				days,				
				estados,
				direccion,				
				router,
				utils
			}
		},
	}
</script> 