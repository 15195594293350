import { createRouter, createWebHistory } from "vue-router";

import Home from '../components/Home';

import Admin_Home from '../components/Admin';
import Admin_Agregar_Proveedor from '../components/Admin_Agregar_Proveedor';
import Admin_Combinar_Clientes from '../components/Admin_Combinar_Clientes';
import Admin_Corte from '../components/Admin_Corte';
import Admin_Payout from '../components/Admin_Payout';
import Admin_Inventario from '../components/Admin_Inventario';
import Admin_Ordenes from '../components/Admin_Ordenes';
import Admin_Proveedor from '../components/Admin_Proveedor';
import Admin_Proveedores from '../components/Admin_Proveedores';
import Admin_Remision from '../components/Admin_Remision';
import Admin_Sub from '../components/Admin_Sub';

import Admin_Proveedor_Cliente from '../components/Admin_Proveedor_Cliente';
import Admin_Proveedor_Entrada from '../components/Admin_Proveedor_Entrada';
import Admin_Proveedor_Entradas from '../components/Admin_Proveedor_Entradas';
import Admin_Proveedor_Orden from '../components/Admin_Proveedor_Orden';
import Admin_Proveedor_Payouts from '../components/Admin_Proveedor_Payouts';
import Admin_Proveedor_Payout from '../components/Admin_Proveedor_Payout';
import Admin_Proveedor_Producto from '../components/Admin_Proveedor_Producto';
import Admin_Proveedor_Productos from '../components/Admin_Proveedor_Productos';
import Admin_Proveedor_Recibos from '../components/Admin_Proveedor_Recibos';
import Admin_Proveedor_Recibo from '../components/Admin_Proveedor_Recibo';

import Cliente from '../components/Cliente';
import Cliente_Orden from '../components/Cliente_Orden';

import Proveedor_Cliente_Agregar_Orden from '../components/Proveedor_Cliente_Agregar_Orden';

import Proveedor from '../components/Proveedor';
import Proveedor_Catalogo from '../components/Proveedor_Catalogo';
import Proveedor_Cliente from '../components/Proveedor_Cliente';
import Proveedor_Cliente_Productos from '../components/Proveedor_Cliente_Productos';
import Proveedor_Clientes from '../components/Proveedor_Clientes';
import Proveedor_Direccion from '../components/Proveedor_Direccion';
import Proveedor_Direcciones from '../components/Proveedor_Direcciones';
import Proveedor_Editar_Cliente from '../components/Proveedor_Editar_Cliente';
import Proveedor_Entregas from '../components/Proveedor_Entregas';
import Proveedor_Ordenes from '../components/Proveedor_Ordenes';
import Proveedor_Producto_Precio from '../components/Proveedor_Producto_Precio';

import Shop from '../components/Shop';
import Pedido from '../components/Pedido';

let admin = [{ 
	path: '/admin/recibos', 
	name: "Admin_Recibos",
	component: Admin_Proveedor_Recibos,
	meta: {
		title: 'Admin recibos',
	}
},{ 
	path: '/admin/payouts', 
	name: "Admin_Payouts",
	component: Admin_Proveedor_Payouts,
	meta: {
		title: 'Admin payouts',
	}
},{ 
	path: '/admin/payout', 
	name: "Admin_Crear_Payout",
	component: Admin_Payout,
	meta: {
		title: 'Admin payout',
	}
},{ 
	path: '/admin/recibo/:recibo_id', 
	name: "Admin_Recibo",
	component: Admin_Proveedor_Recibo,
	meta: {
		title: 'Admin recibo',
	}
},{ 
	path: '/admin/payout/:payout_id', 
	name: "Admin_Payout",
	component: Admin_Proveedor_Payout,
	meta: {
		title: 'Admin payout',
	}
},{ 
	path: '/admin/todochido', 
	name: "Admin_TodoChido",
	component: Admin_Proveedor_Productos,
	meta: {
		title: 'Productos Todo Chido',
	}
},{ 
	path: '/admin/todochido/:producto_id', 
	name: "Admin_TodoChido_Producto",
	component: Admin_Proveedor_Producto,
	meta: {
		title: 'Producto Todo Chido',
	}
},{ 
	path: '/admin/proveedores', 
	name: "Admin_Proveedores",
	component: Admin_Proveedores,
	meta: {
		title: 'Proveedores',
	}
},{ 
	path: '/admin/combinar_clientes', 
	name: "Admin_Combinar_Clientes",
	component: Admin_Combinar_Clientes,
	meta: {
		title: 'Combinar Clientes',
	}
},{ 
	path: '/admin/corte', 
	name: "Admin_Corte",
	component: Admin_Corte,
	meta: {
		title: 'Corte',
	}
},{ 
	path: '/admin/proveedor/:proveedor_id', 
	name: "Admin_Proveedor",
	component: Admin_Proveedor,
	meta: {
		title: 'Proveedor',
	}
},{ 
	path: '/admin/orden/:orden_id', 
	name: "Admin_Orden",
	component: Admin_Proveedor_Orden,
	meta: {
		title: 'Admin_Orden',
	}
},{ 
	path: '/admin/proveedor', 
	name: "Admin_Agregar_Proveedor",
	component: Admin_Agregar_Proveedor,
	meta: {
		title: 'Agregar proveedor',
	}
},{ 
	path: '/admin/ordenes', 
	name: "Admin_Ordenes",
	component: Admin_Ordenes,
	meta: {
		title: 'Ordenes',
	}
},{ 
	path: '/admin/entrada/', 
	name: "Admin_Entrada_Agregar",
	component: Admin_Proveedor_Entrada,
	meta: {
		title: 'Entrada',
	}
},{ 
	path: '/admin/entrada/:entrada_id', 
	name: "Admin_Entrada",
	component: Admin_Proveedor_Entrada,
	meta: {
		title: 'Entrada',
	}
},{ 
	path: '/admin/entradas', 
	name: "Admin_Entradas",
	component: Admin_Proveedor_Entradas,
	meta: {
		title: 'Entradas',
	}
},{ 
	path: '/admin/orden/:orden_id/remision', 
	name: "Admin_Remision",
	component: Admin_Remision,
	meta: {
		title: 'Remision',
	}
},{ 
	path: '/admin/sub', 
	name: "Admin_Sub",
	component: Admin_Sub,
	meta: {
		title: 'Sub',
	}
},{ 
	path: '/admin/cliente/:cliente_id', 
	name: "Admin_Cliente",
	component: Proveedor_Editar_Cliente,
	meta: {
		title: 'Cliente',
	}
},{ 
	path: '/admin/inventario', 
	name: "Admin_Inventario",
	component: Admin_Inventario,
	meta: {
		title: 'Admin Intventario',
	}
},{ 
	path: '/admin', 
	name: "Admin_Home",
	component: Admin_Home,
	meta: {
		title: 'Admin dashboard',
	}
},{ 
	path: '/admin/proveedor_cliente', 
	name: "Admin_Proveedor_Cliente",
	component: Admin_Proveedor_Cliente,
	meta: {
		title: 'Admin_Proveedor_Cliente',
	}
}]

let proveedor = [{ 
	path: '/proveedor/:proveedor_id/entrada/:entrada_id', 
	name: "Proveedor_Entrada",
	component: Admin_Proveedor_Entrada,
	meta: {
		title: 'Entrada',
	}
},{ 
	path: '/proveedor/:proveedor_id/entradas', 
	name: "Proveedor_Entradas",
	component: Admin_Proveedor_Entradas,
	meta: {
		title: 'Entradas',
	}
},{ 
	path: '/proveedor/:proveedor_id/cliente/:cliente_id', 
	name: "Proveedor_Cliente",
	component: Proveedor_Cliente,
	meta: {
		title: 'Editar cliente',
	}
},{ 
	path: '/proveedor/:proveedor_id/direccion/:direccion_id', 
	name: "Editar direccion",
	component: Proveedor_Direccion,
	meta: {
		title: 'Dirección',
	}
},{ 
	path: '/proveedor/:proveedor_id/direccion', 
	name: "Crear direccion",
	component: Proveedor_Direccion,
	meta: {
		title: 'Dirección',
	}
},{ 
	path: '/proveedor/:proveedor_id/direcciones', 
	name: "Direcciones",
	component: Proveedor_Direcciones,
	meta: {
		title: 'Direcciones',
	}
},{ 
	path: '/proveedor/:proveedor_id/payouts', 
	name: "Proveedor_Payouts",
	component: Admin_Proveedor_Payouts,
	meta: {
		title: 'Proveedor payouts',
	}
},{ 
	path: '/proveedor/:proveedor_id/recibos', 
	name: "Proveedor_Recibos",
	component: Admin_Proveedor_Recibos,
	meta: {
		title: 'Proveedor recibos',
	}
},{ 
	path: '/proveedor/:proveedor_id/recibo/:recibo_id', 
	name: "Proveedor_Recibo",
	component: Admin_Proveedor_Recibo,
	meta: {
		title: 'Proveedor recibo',
	}
},{ 
	path: '/proveedor/:proveedor_id/payout/:payout_id', 
	name: "Proveedor_Payout",
	component: Admin_Proveedor_Payout,
	meta: {
		title: 'Proveedor payout',
	}
},{ 
	path: '/proveedor/:proveedor_id/cliente/:cliente_id/editar', 
	name: "Proveedor_Editar_Cliente",
	component: Proveedor_Editar_Cliente,
	meta: {
		title: 'Editar cliente',
	}
},{ 
	path: '/proveedor/:proveedor_id/cliente', 
	name: "Proveedor_Agregar_Cliente",
	component: Proveedor_Editar_Cliente,
	meta: {
		title: 'Proveedor agregar cliente',
	}
},{ 
	path: '/proveedor/:proveedor_id/catalogo', 
	name: "Proveedor_Catalogo",
	component: Proveedor_Catalogo,
	meta: {
		title: 'Proveedor catalogo',
	}
},{ 
	path: '/proveedor/:proveedor_id/cliente/:cliente_id/productos', 
	name: "Proveedor_Cliente_Productos",
	component: Proveedor_Cliente_Productos,
	meta: {
		title: 'Proveedor cliente productos',
	}
},{ 
	path: '/proveedor/:proveedor_id/producto/:producto_id', 
	name: "Proveedor_Producto",
	component: Admin_Proveedor_Producto,
	meta: {
		title: 'Proveedor proudcto',
	}
},{ 
	path: '/proveedor/:proveedor_id/producto/:producto_id/precio', 
	name: "Proveedor_Agregar_Producto_Precio",
	component: Proveedor_Producto_Precio,
	meta: {
		title: 'Proveedor agregar proudcto precio',
	}
},{ 
	path: '/proveedor/:proveedor_id/producto/:producto_id/precio/:producto_precio_id', 
	name: "Proveedor_Editar_Producto_Precio",
	component: Proveedor_Producto_Precio,
	meta: {
		title: 'Proveedor editar proudcto precio',
	}
},{ 
	path: '/proveedor/:proveedor_id/alta', 
	name: "Cliente_Alta",
	component: Proveedor_Editar_Cliente,
	meta: {
		title: 'Alta',
	}
},{ 
	path: '/alta', 
	name: "Alta",
	component: Proveedor_Editar_Cliente,
	meta: {
		title: 'Alta',
	}
},{ 
	path: '/proveedor/:proveedor_id/producto', 
	name: "Proveedor_Agregar_Producto",
	component: Admin_Proveedor_Producto,
	meta: {
		title: 'Agregar producto',
	}
},{ 
	path: '/proveedor/:proveedor_id/productos', 
	name: "Proveedor_Productos",
	component: Admin_Proveedor_Productos,
	meta: {
		title: 'Productos',
	}
},{ 
	path: '/proveedor/:proveedor_id/ordenes', 
	name: "Proveedor_Ordenes",
	component: Proveedor_Ordenes,
	meta: {
		title: 'Ordenes',
	}
},{ 
	path: '/proveedor/:proveedor_id/orden/:orden_id', 
	name: "Proveedor_Orden",
	component: Admin_Proveedor_Orden,
	meta: {
		title: 'Orden',
	}
},{ 
	path: '/proveedor/:proveedor_id/orden', 
	name: "Proveedor_Agregar_Orden",
	component: Proveedor_Cliente_Agregar_Orden,
	meta: {
		title: 'Agregar orden',
	}
},{ 
	path: '/proveedor/:proveedor_id/clientes', 
	name: "Proveedor_Clientes",
	component: Proveedor_Clientes,
	meta: {
		title: 'Clientes',
	}
},{ 
	path: '/proveedor/:proveedor_id/entregas', 
	name: "Proveedor_Entregas",
	component: Proveedor_Entregas,
	meta: {
		title: 'Entregas',
	}
},{ 
	path: '/proveedor/:proveedor_id', 
	name: "Proveedor",
	component: Proveedor,
	meta: {
		title: 'Dashboard',
	}
}]

let cliente = [{ 
	path: '/cliente/:cliente_id/orden', 
	name: "Cliente_Agregar_Orden",
	component: Proveedor_Cliente_Agregar_Orden,
	meta: {
		title: 'Agregar orden',
	}
},{ 
	path: '/cliente/:cliente_id', 
	name: "Cliente",
	component: Cliente,
	meta: {
		title: 'Ordenes',
	}
},{ 
	path: '/cliente/:cliente_id/orden/:orden_id', 
	name: "Cliente_Orden",
	component: Cliente_Orden,
	meta: {
		title: 'Ordenes',
	}
}]

let misc = [{ 
	path: '/', 
	name: "Home",
	component: Home
},{ 
	path: '/shop/:shop_name', 
	name: "Shop",
	component: Shop,
	meta: {
		title: 'Shop',
	}
},{ 
	path: '/shop/pedido/:orden_id', 
	name: "Pedido",
	component: Pedido,
	meta: {
		title: 'Pedido',
	}
}]

const routes = [admin, proveedor, cliente, misc]

export default createRouter({
	history: createWebHistory(),
	routes: routes.flat()
})