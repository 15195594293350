// store.js
import { reactive } from 'vue'

export const store = reactive({
  nombre: "",
  setNombre(nombre) {
    this.nombre = nombre
  },
  user: {},
  setUser(user) {
    this.user = user
  }
})