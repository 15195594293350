<template>
	<div class="flex flex-col h-full">
		<div class="flex-auto overflow-scroll">			
			<h3 v-if="cliente" class="text-2xl font-bold pt-4 px-4">      		
				Productos para {{cliente.nombre}}
			</h3>
			<p class=" pt-4 px-4">
				Por default, productos desactivados no aparecen cuando un cliente está haciendo su orden de compra. Si quieres que el cliente sí tiene acceso a un producto desactivo, seleccionarlo abajo y agregarlo a su selección usando el botón verde.
			</p>
			<div v-if="clienteProductos && clienteProductos.length > 0" class="w-full text-left px-4 pb-4">	
				<div class="border boder-gray-300 rounded p-2 mt-4" v-for="clienteProducto in clienteProductos" v-bind:key="clienteProducto.id">
					<div class="flex flex-row justify-between items-center">						
						<p>{{clienteProducto.producto.nombre}}</p>
						<p class="cursor-pointer underline" @click="deleteClienteProducto(clienteProducto)">Borrar</p>
					</div>
				</div>
			</div>
		</div>

		<div class="p-4 flex-none border-t border-t-gray-300">
			<select v-model="producto" v-if="productos && productos.length > 0" id="countries" class="mb-4 border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
				<option selected disabled value="">Escoger un producto</option>
				<option v-for="producto in productos" v-bind:key="producto.id" :value="producto">{{producto.nombre}}</option>			
			</select>
			<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4" @click="createClienteProducto(producto)" :disabled="loading">
				Agregar producto al cliente
			</button>
		</div>
	</div>
</template>

<script>
	import { supabase } from "../supabase"
	import { useRoute } from 'vue-router'
	import { onMounted, ref} from "vue"
	import { useToast } from 'vue-toast-notification';
	
	export default {
		name: 'Cliente Productos',
		setup() {
			const cliente = ref(null)
			const productos = ref(null)
			const clienteProductos = ref(null)
			const producto = ref(null)
			const route = useRoute()
			const loading = ref(false)

			async function getCliente() {
				let { data, error, status } = await supabase
				.from("clientes")
				.select('*')          
				.eq("id", route.params.cliente_id)
				.single()

				if (error && status !== 406) {
					throw error
				}

				if (data) {             					
					cliente.value = data
				} else if (error) { 
					alert(error)     
				}   
			}   

			async function getProductos() {
				let { data, error, status } = await supabase
				.from("productos")
				.select('id, nombre, precio, activo, cantidad')       
				.eq('proveedor', route.params.proveedor_id)
				.eq('activo', false)
				.order('activo', { ascending: false })
				.order('nombre', { ascending: false })

				if (error && status !== 406) {
					throw error
				}

				if (data) {              
					productos.value = data
				} else if (error) { 
					console.log(error) 
					alert(error)     
				}   
			}

			async function getClienteProductos() {
				let { data, error, status } = await supabase
				.from("cliente_producto")
				.select('id, producto(*)')       
				.eq('producto.proveedor', route.params.proveedor_id)
				.eq('cliente', route.params.cliente_id)				

				if (error && status !== 406) {
					throw error
				}

				if (data) {       				 
					clienteProductos.value = data
				} else if (error) { 
					console.log(error) 
					alert(error)     
				}   
			}

			async function deleteClienteProducto(clienteProducto) {
				console.log(clienteProducto)

				const { data, error } = await supabase
				.from('cliente_producto')
				.delete()
				.match({ id: clienteProducto.id })		

				if (data) {              
					getClienteProductos()
				} else if (error) { 
					console.log(error) 
					alert(error)     
				}   	
			}

			async function createClienteProducto(producto) {
				if (!producto) {
					useToast().warning(`Debes de escoger un producto.`, { position: "top-right" });				
				}

				let { data, error } = await supabase
				.from('cliente_producto')
				.insert([{
					"cliente": route.params.cliente_id,
					"producto": producto.id
				}])				

				if (data) {
					getClienteProductos()	
					producto.value = null					
				} else if (error) {  	
					useToast().warning(`Hubo un error, favor de intentarlo otra vez.`, { position: "top-right" });				
					throw error     
				}     
			}

			onMounted( async () => {
				getCliente()
				getProductos()
				getClienteProductos()
			})

			return {      				
				loading,
				cliente,
				productos,
				createClienteProducto,
				clienteProductos,
				producto,
				deleteClienteProducto
			}
		},
	}
</script>