<template>
	<div class="h-full overflow-y-scroll">
		<div class=""> 
			<div class="flex justify-between items-center content-center pt-4">
				<h3 class="text-2xl font-bold px-4">      		
					Ordenes
				</h3>

				<div class="text-left flex-none pr-4">
					<button class="rounded bg-primary hover:bg-primary-dark text-white font-bold p-1 px-4"  @click="generateCSV()">
						Descargar
					</button>
				</div>
			</div>
			
				<div class="w-full text-left px-4 pb-2 pt-4">
				<select @change="updatePedidos()" v-model="proveedor" v-if="proveedores.length > 0" id="countries" class="border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
					<option selected value="">Últimos 100 ordenes</option>					
					<option v-for="proveedor in proveedores" v-bind:key="proveedor.id" :value="proveedor">{{proveedor.nombre}}</option>			
				</select>
			</div>
			
			<div v-if="pedidos && pedidos.length > 0" class="pb-4"> 
				<inbox class="mt-4" :user="'admin'" :ordenes="pedidos" :fields="{cliente: true, evidencia: true, surtir: true, aprobar: false, facturar: false, entregar: true}"></inbox>
			</div>
		</div>
	</div>
</template>

<script>
	import { supabase } from "../supabase"
	import { useRoute, useRouter } from 'vue-router'
	import { onMounted, ref } from "vue"
	import { utils } from "./utils/utils.js"	

	import { useLoading } from 'vue-loading-overlay'

	const loading = useLoading({
		color: "#428b74",
		loader: "spinner"
	});

	export default {
		name: 'Admin Ordenes',
		setup() {			
			const route = useRoute()
			const router = useRouter()
			const pedidos = ref([])	
			const proveedores = ref([])	
			const proveedor = ref("")		

			async function getPedidos(proveedor_id) {
				
				let loader = loading.show()
				if (proveedor_id) {
					let { data, error } = await supabase
					.from("pedidos")
					.select('*, envios(*), entregado, pedido_factura(*), proveedor(id, nombre), cliente(*), line_items(id, pedido, cantidad, cantidad_entregada, precio, producto(id, nombre, precio))')
					.order('created_at', { ascending: false })								
					.eq("proveedor", proveedor.value.id)
					.neq('standalone', true)													

					loader.hide()
					if (data) {              
						return data
					} else if (error) { 
						alert(error)     
					}   
				} else {
					let { data, error } = await supabase
					.from("pedidos")
					.select('*, envios(*), entregado, pedido_factura(*), proveedor(id, nombre), cliente(*), created_at, surtido, line_items(id, pedido, cantidad, cantidad_entregada, precio, producto(id, nombre, precio))')
					.order('created_at', { ascending: false })		
					.neq('standalone', true)						
					.limit(100)																			

					loader.hide()
					if (data) {              
						return data
					} else if (error) { 
						alert(error)     
					}   
				}

			}

			async function updatePedidos() {				
				getPedidos(proveedor.value.id)
				.then( data => {
					pedidos.value = data.filter( pedido => { 
						if (pedido.require_aprobacion && !pedido.aprobado) {
							return false
						}

						return true
					})
				})
				updateQuery()
			}

			function generateCSV() {
				let csv = "Pedido, Cancelado, Surtido, Entregado, Proveedor, Cliente, Producto, Cantidad pedida, Cantidad entregada" 

				pedidos.value.forEach( pedido => {
					console.log(pedido)
					pedido.line_items.forEach( lineItem => {
						csv += `\n${pedido.id}, ${pedido.cancelado}, ${pedido.surtido},${pedido.entregado || "false"},${pedido.proveedor ? pedido.proveedor.nombre.replaceAll(",","") : ""},${pedido.cliente ? pedido.cliente.nombre.replaceAll(",","") : ""},${lineItem.producto.nombre.replaceAll(",","")},${lineItem.cantidad}, ${lineItem.cantidad_entregada || ""}`
					})
				})

				const anchor = document.createElement('a');
				anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
				anchor.target = '_blank';
				anchor.download = 'ordenes.csv';
				anchor.click();

			}
			
			function updateQuery() {	
				if (proveedor.value.id) {
					let data = { 
						path: '/admin/ordenes', 
						query: { proveedor: proveedor.value.id }
					}

					router.push(data)
				} else {
					let data = {
						path: '/admin/ordenes', 
					}
					router.push(data)				
				}
			}

			onMounted( async () => {				
				
				
				proveedores.value = await utils.getProveedores()
				
				if (route.query.proveedor) {
					proveedor.value = proveedores.value.find( p => p.id == route.query.proveedor)
					getPedidos(proveedor.value.id)
					.then( data => {
						pedidos.value = data.filter( pedido => { 
							if (pedido.require_aprobacion && !pedido.aprobado) {
								return false
							}

							return true
						})
					})
				} else {
					getPedidos()
					.then( data => {
						pedidos.value = data.filter( pedido => { 
							if (pedido.require_aprobacion && !pedido.aprobado) {
								return false
							}

							return true
						})
					})
				}
			})

			return {      
				pedidos,
				proveedor,
				proveedores,
				updatePedidos,
				generateCSV,
				updateQuery
			}
		},
	}
</script> 
