<template>
	<div class="flex flex-col h-full">
		<div class="flex-auto overflow-scroll">
			<h3 class="text-2xl font-bold pt-4 px-4 flex content-center">  
				<div @click="utils.goBack(router, admin ? '/admin/todochido' : `/proveedor/${route.params.proveedor_id}/productos`)" class="pr-2 flex items-center cursor-pointer">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
						<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
					</svg>
				</div>

				{{route.params.producto_id ? "Editar producto" : "Agregar producto"}}				
			</h3>

			<div class="block md:flex">
				<div class="w-full md:w-8/12 p-4">
					<div class="w-full">
						<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
							Nombre
						</label>
						<input class="appearance-none border order-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500  w-full mb-2" id="nombre" type="text" placeholder="Nombre" v-model="producto.nombre">						
					</div>
					
					<div class="mt-4 w-full">
						<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
							Descripción
						</label>
						<!-- <input class="appearance-none border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500 w-full mb-2" id="nombre" type="number" placeholder="0" v-model="producto.precio_publico">				 -->
						<textarea class="appearance-none focus:border-gray-500 border rounded w-full py-2 pb-0 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="Instrucciones" cols="40" rows="3" v-model="producto.texto"></textarea>
					</div>

					<div class="mt-4 w-full">
						<label class="block text-sm mb-2" for="nombre">
							<p class="font-bold text-gray-700">Disponible B2B</p>
							<p class="font-regular text-gray-500">Los productos disponibles aparecen para los clientes cuando hagan sus pedidos. Los productos no activos solo aparacen cuando agregas un orden manual.</p>
						</label>


						<div class="border border-primary rounded flex flex-row bg-white">
							<button @click="producto.activo = false" :class="[{'bg-primary hover:bg-primary-dark text-white': producto.activo == false, 'text-primary': producto.activo == true}]" class="w-full font-bold py-2 px-4" >
								Desactivado
							</button>
							<button @click="producto.activo = true" :class="[{'bg-primary hover:bg-primary-dark text-white': producto.activo == true, 'text-primary': producto.activo == false}]" class="w-full font-bold py-2 px-4" >
								Activo
							</button>	
						</div>
					</div>

					<div class="mt-4 w-full">
						<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
							Precio B2B
						</label>
						<input class="appearance-none border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500 w-full mb-2" id="nombre" type="number" placeholder="0" v-model="producto.precio">				
					</div>

					<div v-if="producto.id" class="mt-4 w-full">
						<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
							Precios B2B adicionales
						</label>

						<div class="border boder-gray-300 rounded mt-2 bg-white">
							<table class="w-full text-sm text-left" v-if="productoPrecios && productoPrecios.length > 0">
								<thead class="text-s ">													
									<th scope="col" class="py-3 px-6 w-3/12">
										Nombre
									</th>						
									<th scope="col" class="py-3 px-6 w-3/12">
										Precio
									</th>	
									<th scope="col" class="py-3 px-6 w-3/12">

									</th>
									<th scope="col" class="py-3 px-6 w-3/12">

									</th>						
								</thead>
								<tbody v-for="productoPrecio in productoPrecios" v-bind:key="productoPrecio.id" class="text-s border-t">						
									<td class="py-3 px-6 whitespace-nowrap">												
										{{productoPrecio.nombre}}
									</td>						
									<td class="py-3 px-6 whitespace-nowrap">
										${{productoPrecio.precio}}
									</td> 
									<td>
										<router-link :to=' "/proveedor/" + producto.proveedor + "/producto/" + producto.id + "/precio/" + productoPrecio.id '>
											<p class="cursor-pointer underline" @click="productoPrecio.editar = true">Editar</p>
										</router-link>
									</td> 
									<td>
										<p class="cursor-pointer underline" @click="deleteProductoPrecio(productoPrecio)">Eliminar</p>
									</td>
								</tbody>
							</table>

							<div :class="{ 'mt-4' : productoPrecios && productoPrecios.length == 0 }" class="px-4 pb-4">
								<router-link :to=' "/proveedor/" + producto.proveedor + "/producto/" + producto.id + "/precio" '>
									<button class="w-full rounded mr-4 border border-primary hover:border-primary-dark hover:text-primary-dark text-primary font-bold p-2 px-4">
										Agregar precio
									</button>
								</router-link>
							</div>
						</div>
					</div>

					<div class="mt-4 w-full" v-if="proveedor.ecom">
						<label class="block text-sm mb-2" for="nombre">
							<p class="font-bold text-gray-700">Disponible B2C</p>							
						</label>


						<div class="border border-primary rounded flex flex-row bg-white">
							<button @click="producto.ecom = false" :class="[{'bg-primary hover:bg-primary-dark text-white': producto.ecom == false, 'text-primary': producto.ecom == true}]" class="w-full font-bold py-2 px-4" >
								No disponible
							</button>
							<button @click="producto.ecom = true" :class="[{'bg-primary hover:bg-primary-dark text-white': producto.ecom == true, 'text-primary': producto.ecom == false}]" class="w-full font-bold py-2 px-4" >
								Disponible
							</button>	
						</div>
					</div>
					
					<div class="mt-4 w-full" v-if="proveedor.ecom && producto.ecom">
						<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
							Precio B2C
						</label>
						<input class="appearance-none border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500 w-full mb-2" id="nombre" type="number" placeholder="0" v-model="producto.precio_publico">				
					</div>					


					<div v-if="admin" class="mt-4 w-full">
						<label class="block text-sm mb-2" for="nombre">
							<p class="font-bold text-gray-700">Todo Chido</p>							
						</label>

						<div class="border border-primary rounded flex flex-row bg-white">
							<button @click="producto.todoChido = false" :class="[{'bg-primary hover:bg-primary-dark text-white': producto.todoChido === false, 'text-primary': producto.todoChido === true}]" class="w-full font-bold py-2 px-4" >
								Desactivado
							</button>
							<button @click="producto.todoChido = true" :class="[{'bg-primary hover:bg-primary-dark text-white': producto.todoChido === true, 'text-primary': producto.todoChido === false}]" class="w-full font-bold py-2 px-4" >
								Activo
							</button>	
						</div>	
					</div>

					<div v-if="admin" class="mt-4 w-full">
						<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
							Precio Todo Chido
						</label>
						<input class="appearance-none border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500 w-full mb-2" id="nombre" type="number" placeholder="0" v-model="producto.precioTodoChido">				
					</div>

				</div>
				<div class="w-full md:w-4/12 p-4">
					<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
						Foto
					</label>

					<div v-if="producto.foto" class="rounded overflow-hidden w-1/2 mb-2 m-auto">
						<img :src="producto.foto" class="">
					</div>

					<div class="cursor-pointer text-center w-full bg-primary hover:bg-primary-dark rounded text-white font-bold">
						<label class="cursor-pointer inline-block w-full py-2 px-4" for="single">{{producto.foto ? "Actualizar foto" : "Subir foto"}}</label>
						<input
						class="hidden"
						type="file"
						id="single"
						accept="image/png, image/jpeg"
						@change="uploadFoto(producto, $event)"
						/>
					</div>

					<div class="mt-4 w-full">
						<label class="block text-sm mb-2" for="nombre">
							<p class="font-bold text-gray-700">Archivado</p>
							<p class="font-regular text-gray-500">Los productos archivado no aperecen ni para los clientes ni para ti. Siempre puedes desarchivar un producto en caso de que regrese a la venta.</p>
						</label>

						<div v-if="producto.archivado != true" class="mt-4">
							<button class="w-full bg-white border border-red hover:border-red-dark rounded text-red font-bold py-2 px-4 " @click="archivarProducto(true)">
								Archivar producto
							</button>
						</div>
						<div v-if="producto.archivado == true" class="mt-4">
							<button class="w-full bg-white border border-primary hover:border-primary-dark rounded text-primary font-bold py-2 px-4 " @click="archivarProducto(false)">
								Desarchivar producto
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="p-4 flex-none border-t border-t-gray-300">
			<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4" @click="updateProducto()">
				{{producto.id ? "Actualizar producto" : "Crear producto"}}	
			</button>						
		</div>

	</div>
</template>

<script>	
	import { useRoute, useRouter } from 'vue-router'
	import { onMounted, ref } from "vue"
	import { useToast } from 'vue-toast-notification';
	import { utils } from './utils/utils.js'
	import { useLoading } from 'vue-loading-overlay'

	export default {
		name: 'Order',
		setup() {
			const route = useRoute()		
			const router = useRouter()	
			const producto = ref({ activo: true, ecom: false})	
			const proveedor = ref({})		
			const productoPrecios = ref([])
			const admin = ref(false)	

			const loading = useLoading({
				color: "#428b74",
				loader: "spinner"
			});			

			async function archivarProducto(archivado) {
				producto.value.archivado = archivado

				let loader = loading.show()

				utils.updateProducto(producto.value)
				.then( () => {
					loader.hide()					
					useToast().success('Producto actualizado', { position: "top-right", duration: 5000 });	
				})
				.catch( error => {
					console.log(error)
					loader.hide()
					alert('Hubo un error, favor de intentarlo otra vez.')
				})
			}

			async function updateProducto() {
				if (!producto.value.nombre || !producto.value.precio) {
					return useToast().warning('Asegúrate que todos los datos están', { position: "top-right" });
				}			

				if (producto.value.precio == 0) {
					return useToast().warning('El producto debe de tener un precio arriba de $0', { position: "top-right" });
				}

				let data = { 
					proveedor: route.params.proveedor_id ? route.params.proveedor_id : producto.value.proveedor,
					nombre: producto.value.nombre,
					activo: producto.value.activo,
					precio: producto.value.precio,					
					ecom: producto.value.ecom				
				}

				if (producto.value.precio_publico) {
					data.precio_publico = producto.value.precio_publico
				} else {
					data.precio_publico = null
				}

				if (producto.value.texto) {
					data.texto = producto.value.texto
				} else {
					data.texto = null
				}

				if (producto.value.id) {
					data.id = producto.value.id
				}

				if (producto.value.foto) {
					data.foto = producto.value.foto
				}

				if (admin.value == true) {
					data.precioTodoChido = producto.value.precioTodoChido
					data.todoChido = producto.value.todoChido
				}

				let loader = loading.show()

				utils.updateProducto(data)
				.then( data => {
					loader.hide()
					
					if (producto.value.id) {
						useToast().success('Producto actualizado', { position: "top-right", duration: 5000 });	
					} else {
						producto.value = data
						useToast().success('Producto creado', { position: "top-right", duration: 5000 });	
						router.push(`/proveedor/${proveedor.value.id}/productos`) // There was an issue with  going back 		
					}
				})
				.catch( error => {
					console.log(error)
					loader.hide()
					alert('Hubo un error, favor de intentarlo otra vez.')
				})
			}   

			const uploadFoto= async (pedido, evt) => {
				let loader = loading.show()

				uploadFile(evt)
				.then( fileName => {					
					return getURL(fileName)
				})
				.then( url => {					
					producto.value.foto = url
					useToast().success('Foto subido', { position: "top-right", duration: 5000 });

					if (producto.value.id) {
						utils.updateProducto(producto.value)
						.then( () => {
							loader.hide()
							useToast().success('Producto actualizado', { position: "top-right", duration: 5000 });					
						})
						.catch( error => {
							console.log(error)
							loader.hide()
							alert('Hubo un error, favor de intentarlo otra vez.')
						})
					} else {
						loader.hide()
					}
				})	
				.catch( error => {
					console.log(error)
					loader.hide()
					alert("Hubo un error, favor de intentarlo otra vez.")
				})			
			}

			async function uploadFile(evt) {									
				if (!evt.target.files || evt.target.files.length === 0) {
					throw new Error("Selecciona un archivo")
				}
				const file = evt.target.files[0]
				const fileExt = file.name.split(".").pop()
				const fileName = `${Math.random()}.${fileExt}`
				const filePath = `${fileName}`					

				return await utils.uploadFile('foto', filePath, file)						
			}

			async function getURL(file) {	
				console.log(file)
				let fileOnly = file.split('foto/')[1]
				return await utils.getURL('foto', fileOnly)				
			}	

			async function deleteProductoPrecio(productoPrecio) {
				if (confirm("¿Quieres eliminar este precio especial? \n\nTodos los precios que agregaste a los clientes también se borraría.")) {
					let loader = loading.show()
					utils.deleteProductoPrecio(productoPrecio.id)
					.then( () => {
						loader.hide()
						productoPrecios.value = productoPrecios.value.filter( pP => pP.id !== productoPrecio.id )	
						useToast().success('Precio eliminado', { position: "top-right", duration: 5000 });									
					})
					.catch( error => {
						console.log(error)
						loader.hide()
						alert("Hubo un error, favor de intentarlo otra vez.")
					})
				} else {
					return
				}	
			}

			onMounted( async () => {				
				if (route.name == "Admin_TodoChido_Producto" || route.query.admin == "true") {
					admin.value = true
				}

				if (route.params.producto_id) {
					utils.getProducto(route.params.producto_id)
					.then( data => {
						producto.value = data
					})

					utils.getProductoPrecios(route.params.producto_id)
					.then( data =>{
						productoPrecios.value = data
					})
				}				

				if (route.params.proveedor_id) {
					utils.getProveedor(route.params.proveedor_id)
					.then( data => {
						proveedor.value = data
					})		
				}
			})

			return {      				
				producto,				
				updateProducto,
				proveedor,
				uploadFoto,
				productoPrecios,
				deleteProductoPrecio,
				route,
				router,
				utils,
				archivarProducto,
				admin
			}
		},
	}
</script> 