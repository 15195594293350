<template>
	<div class="flex flex-col h-full">
		<div class="flex-auto overflow-scroll">
			<h3 class="text-2xl font-bold pt-4 px-4">      	
				Crear link de PickPack
			</h3>			

			<div class="px-4 pt-4" >
				<label class="block text-gray-700 text-sm font-bold mb-2 mt-0" >
					Proveedor
				</label>
				<select v-model="proveedor" v-if="proveedores && proveedores.length > 0" id="countries" class="border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
					<option selected disabled value="">Escoger un proveedor</option>
					<option v-for="proveedor in proveedores" v-bind:key="proveedor.id" :value="proveedor">{{proveedor.nombre}}</option>			
				</select>				
			</div>

			<div class="px-4 pt-4" >
				<label class="block text-gray-700 text-sm font-bold mb-2 mt-0" >
					Suscripción
				</label>
				<select v-model="sub" id="countries" class="border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
					<option selected disabled value="">Escoger un precio</option>
					<option value="a">$2995</option>	
					<option value="b">$1995</option>	
					<option value="c">$995</option>	
					<option value="d">$495</option>	
				</select>				
			</div>
			
			<div class="px-4 pt-4" @click="copyLink()">
				<input class="cursor-pointer appearance-none border border-gray-300 rounded py-2 px-3 select-none leading-tight focus:outline-none focus:border-gray-500  w-full mb-2" id="nombre" type="text" placeholder="Escoge un proveedor y una suscripción" disabled v-model="link">					
			</div>

		</div>
		<!-- <div class="p-4 flex-none border-t border-t-gray-300">		
			<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4">
				Crear link
			</button>
		</div> -->
	</div>
</template>

<script>	
	import { onMounted, ref, computed } from "vue"
	import { utils } from './utils/utils.js'
	import { useToast } from 'vue-toast-notification';
	
	export default {
		name: 'Admin proveedores',
		setup() {
			const proveedores = ref(null)			
			const proveedor = ref("")			
			const sub = ref("")			

			onMounted( async () => {
				proveedores.value = await utils.getProveedores()
			})

			const link = computed( () => {

				if (!proveedor.value || !sub.value){
					return null
				} 

				return `https://pickpack.mx/api/sub?proveedor=${proveedor.value.id}&sub=${sub.value}`

			})

			function copyLink() {	

				if (link.value != null) {
					navigator.clipboard.writeText(link.value);
					useToast().success('Enlace copiado', { position: "top-right" });
				}				
			}

			return {      				
				proveedores,
				proveedor,
				sub,
				link,
				copyLink			
			}
		},
	}
</script> 