<template>
	<div class="flex flex-col h-full">
		<div class="flex-auto overflow-scroll">
			<div class="pt-4 px-4 mb-4">
				<div class="flex content-center">
					<div @click="utils.goBack(router, admin ? '/admin/payouts' : `/proveedor/${route.params.proveedor_id}/payouts`)" class="pr-2 flex items-center cursor-pointer"> 				
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
							<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
						</svg>
					</div>	
					<h3 v-if="payout" class="text-2xl font-bold">
						Payout {{payout.mes}}/{{payout.año}}
					</h3>
				</div>	
			</div>

			<div v-if="payout" class="w-full text-left p-4 pt-0">
				<div class="border boder-gray-300 rounded bg-white">
					<div class="overflow-scroll">
						<table class="w-full text-sm text-left ">
							<thead class="text-s border-b border-b-gray-200">														
								<th scope="col" class="py-3 px-6">
									Venta total
								</th>					
							<!-- 	<th scope="col" class="py-3 px-6">
									Comisión %
								</th> -->				
								<th scope="col" class="py-3 px-6">
									Comisión $ (c/IVA)
								</th>					
								<th scope="col" class="py-3 px-6">
									Payout
								</th>												
							</thead>
							<tbody>
								<tr class="text-s">									
									<td class="py-3 px-6">							
										${{Math.round(payout.total * 100)/100}}
									</td>
									<!-- <td class="py-3 px-6 ">							
										{{payout.comision_percentage}}%
									</td> -->
									<td class="py-3 px-6">							
										${{Math.round(payout.comision * 1.16 * 100)/100}}
									</td>	
									<td class="py-3 px-6">							
										${{Math.round((payout.total - (payout.comision * 1.16)) * 100)/100}}
									</td>										
								</tr>              
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<label class="block text-gray-700 text-sm font-bold pl-4">
				Pedidos
			</label>

			<empty v-if="pedidos && pedidos.length == 0" :texto="'No hubo entregas'"> </empty>

			<div v-if="pedidos && pedidos.length > 0" class="w-full text-left p-4 ">
				<div class="border boder-gray-300 rounded bg-white overflow-hidden">
					<div class="overflow-scroll">
						<table class="w-full text-sm text-left ">
							<thead class="text-s border-b border-b-gray-200">						
								<th scope="col" class="py-3 px-6">
									Pedido
								</th>
								<th scope="col" class="py-3 px-6">
									Fecha
								</th>	
								<th scope="col" class="py-3 px-6">
									Venta
								</th>					
								<th scope="col" class="py-3 px-6">
									Comisión (c/IVA)
								</th>				
								<!-- <th scope="col" class="py-3 px-6">
									Payout
								</th>	 -->									
							</thead>
							<tbody>
								<tr class="text-s border-b" v-for="pedido in pedidos" v-bind:key="pedido.id">
									<td class="py-3 px-6 underline">								
										<router-link :to="`/proveedor/${route.params.proveedor_id}/orden/${pedido.id}`">
											{{pedido.id}}
										</router-link> 										
									</td>
									<td class="py-3 px-6">							
										{{pedido.created_at ? (new Date(pedido.created_at)).toLocaleDateString("en-GB", { timeZone: 'America/Mexico_City' }) : ""}}
									</td>
									<td class="py-3 px-6  ">							
										${{Math.round(getPedidoTotal(pedido) * 100)/100}}
									</td>						
									<td class="py-3 px-6  ">
										${{Math.round(getPedidoComision(pedido) * 100)/100}}
									</td>
									<!-- <td class="py-3 px-6">
										${{Math.round( (getPedidoTotal(pedido) - getPedidoComision(pedido)) * 100 )/100}}
									</td> -->
								</tr>              
							</tbody>
							<tfoot class="">
								<tr>
									<th scope="col" class="py-3 px-6">
										Total
									</th>
									<th scope="col" class="py-3 px-6">
										
									</th>	<th scope="col" class="py-3 px-6  ">
										${{Math.round(payout.total * 100)/100}}
									</th>
									<th scope="col" class="py-3 px-6  ">
										${{Math.round(payout.comision * 100 * 1.16)/100}}
									</th>
									<!-- <th scope="col" class="py-3 px-6">
										${{Math.round((payout.total - (payout.comision)) * 100)/100}}
									</th> -->
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { useRoute, useRouter } from 'vue-router'
	import { supabase } from "../supabase"
	import { onMounted, ref } from "vue"
	import { utils } from './utils/utils.js'	

	export default {		
		setup() {
			const route = useRoute()
			const router = useRouter()
			const pedidos = ref(null)	
			const payout = ref(null)
			const admin = ref(false)

			async function getPedidos() {
				let { data, error } = await supabase
				.from("pedidos")
				.select('*, proveedor(*, pricing(*)), line_items(*)')       
				.order('created_at', { ascending: true })
				.eq("payout", route.params.payout_id)				

				if (data) {              
					return data
				} else if (error) { 
					console.log("getPedidos", error)       
					throw error 
				}   
			} 

			onMounted( async () => {
				payout.value = await utils.getPayout(route.params.payout_id)				
				pedidos.value = await getPedidos()							

				if (route.name == "Admin_Payout") {
					admin.value = true
				}								
			})							

			function getPedidoTotal(pedido) {
				return (pedido.line_items.map( li => li.cantidad * li.precio ).reduce((a, b) => a + b)) 
			}

			function getPedidoComision(pedido) {
				return ((pedido.line_items.map( li => li.cantidad * li.precio ).reduce((a, b) => a + b)  * (payout.value.comision_percentage / 100) * 1.16))
			}

			return {
				route,
				router,
				utils,
				pedidos,	
				payout,				
				admin,
				getPedidoTotal,
				getPedidoComision,				
			}
		}
	}
</script>