import { createApp } from 'vue/dist/vue.esm-bundler';
import router from "./router";

import App from './App.vue'
import './index.css'
import './assets/tailwind.css'
import './assets/toast.css'

import { LoadingPlugin } from 'vue-loading-overlay';

import empty from "./components/parts/Empty"
import inbox from "./components/parts/Inbox"
import surtir from "./components/parts/Surtir"
import payment from "./components/parts/Payment"
import entregar from "./components/parts/Entregar"
import status from "./components/parts/Status"
import 'vue-loading-overlay/dist/css/index.css';

const app = createApp(App);

import ToastPlugin from 'vue-toast-notification';

app.use(LoadingPlugin);
app.use(ToastPlugin);
app.use(router);
app.mount("#app");

app.component('empty', empty)
app.component('inbox', inbox)
app.component('status', status)
app.component('surtir', surtir)
app.component('entregar', entregar)
app.component('payment', payment)


