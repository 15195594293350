<template>
	<div class="flex flex-col h-full">
		<div class="flex-auto overflow-scroll">

			<h3 class="text-2xl font-bold pt-4 px-4">      		
				Inventario
			</h3>
			
			<div class="w-full text-left px-4 pb-2 pt-4">
				<select @change="updateProductos()" v-model="proveedor" v-if="proveedores.length > 0" id="countries" class="border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
					<option selected disabled value="">Escoger un proveedor</option>
					<option v-for="proveedor in proveedores" v-bind:key="proveedor.id" :value="proveedor">{{proveedor.nombre}}</option>			
				</select>
			</div>

			<div v-if="productos && productos.length > 0" class="w-full text-left p-4">
				<div class="border border-b-0 boder-gray-300 rounded bg-white">
					<div class="overflow-scroll">	
						<table class="w-full text-sm text-left ">
							<thead class="text-s border-b border-b-gray-200">		
								<tr>
									<th scope="col" class="py-3 px-6 w-4/6">
										Nombre
									</th>
									<th scope="col" class="py-3 px-6 w-2/6">
										Cantidad
									</th>														
								</tr>
							</thead>
							<tbody>
								<tr class="text-s border-b" v-for="producto in productos.sort((a,b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0))" v-bind:key="producto.id">

									<th scope="row" class="py-3 px-6 font-medium text-gray-900 ">

										<div class="flex items-center" >
											<div v-if="producto.foto" class="bg-contain bg-no-repeat bg-center rounded overflow-hidden h-20 w-20 mr-4" v-bind:style="{ backgroundImage: 'url(' + producto.foto + ')' }">											
											</div>

											{{producto.nombre}}
										</div>
									</th>
													
									
									<td class="py-3 px-6">							
										<input class="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full" id="nombre" type="number" placeholder="0" v-model="producto.cantidad">
									</td>						
								</tr>              
							</tbody>				
						</table>
					</div>
				</div>
			</div>
		</div>
		<div class="p-4 flex-none border-t border-t-gray-300">							
			<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4" @click="updateInventory(proveedor.productos)">
				Actualizar inventario
			</button>
		</div>
	</div>
</template>

<script>
	import { supabase } from "../supabase"	
	import { onMounted, ref } from "vue"
	import { useToast } from 'vue-toast-notification';
	import { utils } from "./utils/utils.js"		
	import { useLoading } from 'vue-loading-overlay'

	const loading = useLoading({
		color: "#428b74",
		loader: "spinner"
	});

	export default {
		name: 'Order',
		setup() {
			const proveedor = ref("")		
			const proveedores = ref([])		
			const productos = ref([])

			async function updateInventory() {
				let loader = loading.show()
				const { data, error } = await supabase.rpc('admin_inventory', {
					payload: productos.value.map( p => {
						return {
							id: p.id,
							cantidad: p.cantidad
						}
					})
				});				
				loader.hide()
				if (data) {  
					useToast().success('Inventrario actualizado', { position: "top-right" });
				} else if (error) {  
					useToast().error('Error al actualizar el inventario', { position: "top-right" });
				} 				
			}

			async function updateProductos() {
				let loader = loading.show()
				productos.value = await utils.getProductosPorProveedor(proveedor.value.id)
				loader.hide()
			}

			onMounted( async () => {				
				proveedores.value = await utils.getProveedores()
			})

			return {      								
				proveedores,
				proveedor,
				updateInventory,
				updateProductos,
				productos
			}
		},
	}
</script> 