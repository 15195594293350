<template>
	<div class="flex flex-col h-full">
		<div class="flex-auto overflow-scroll  pb-4">
			<h3 class="text-2xl font-bold pt-4 px-4">
				Combinar clientes
			</h3>

			<div class="px-4 pt-4" >
				<label class="block text-gray-700 text-sm font-bold mb-2 mt-0" >
					Cliente 
				</label>
				<select v-model="cliente" v-if="clientes && clientes.length > 0" id="countries" class="border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
					<option selected disabled value="">Escoger un cliente</option>
					<option v-for="cliente in clientes.filter( c => !c.redirect_to )" v-bind:key="cliente.id" :value="cliente">{{cliente.nombre}}</option>			
				</select>				
			</div>			
			{{cliente ? cliente.id : ""}}
			<div v-if="cliente" class="mx-4 bg-white rounded p-4 border mt-4">
				<p class="font-medium">Dirección</p>						
				<p>{{cliente.direccion_calle}} {{cliente.direccion_exterior}}</p>
				<p>{{cliente.direccion_interior}}</p>
				<p>{{cliente.direccion_colonia}} {{cliente.direccion_codigo}}</p>						
				<p>{{cliente.direccion_estado}}</p>
			</div>

			<div v-if="cliente && cliente.cliente_proveedor.length > 0" class="hidden sm:block w-full text-left px-4 mt-4">		
				<div class="border boder-gray-300 border-b-0 rounded overflow-hidden mt-4 bg-white">
					<div class="overflow-scroll">
						<table class="w-full text-sm text-left ">
							<thead class="text-s border-b border-b-gray-200">																	
								<th scope="col" class="py-3 px-6">
									Proveedor
								</th>						
							</thead>
							<tbody v-for="cliente_proveedor in cliente.cliente_proveedor" v-bind:key="cliente_proveedor.id" class="text-s border-b">						
								<td scope="row" class="py-3 px-6 whitespace-nowrap">
									{{cliente_proveedor.proveedor.nombre}}
								</td>

							</tbody>
						</table>
					</div>		
				</div>
			</div>			

			<div v-if="cliente && cliente.pedidos.length > 0" class="hidden sm:block w-full text-left px-4 mt-4 pb-4">		
				<div class="border boder-gray-300 border-b-0 rounded overflow-hidden mt-4 bg-white">
					<div class="overflow-scroll">
						<table class="w-full text-sm text-left ">
							<thead class="text-s border-b border-b-gray-200">													
								<th scope="col" class="py-3 px-6">
									Fecha
								</th>
								<th scope="col" class="py-3 px-6">
									Proveedor
								</th>						
							</thead>
							<tbody v-for="pedido in cliente.pedidos" v-bind:key="pedido.id" class="text-s border-b">						
								
								<td class="py-3 px-6 whitespace-nowrap">
									{{pedido.created_at.split("T")[0]}}
								</td>
								<td scope="row" class="py-3 px-6 whitespace-nowrap">
									{{pedido.proveedor.nombre}}
								</td>
							</tbody>
						</table>
					</div>		
				</div>
			</div>

			<empty v-if="cliente && cliente.pedidos.length == 0" :texto=" 'Este cliente no tiene pedidos' "> </empty>

			<div class="px-4 pt-4" >
				<label class="block text-gray-700 text-sm font-bold mb-2 mt-0" >
					Cliente Duplicado
				</label>
				<select v-model="cliente_duplicado" v-if="clientes && clientes.length > 0" id="countries" class="border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
					<option selected disabled value="">Escoger un cliente duplicado</option>
					<option v-for="cliente in clientes.filter( c => cliente ? c.id != cliente.id : true ).filter( c => !c.redirect_to )" v-bind:key="cliente.id" :value="cliente">{{cliente.nombre}}</option>			
				</select>				
			</div>

			{{cliente_duplicado ? cliente_duplicado.id : ""}}

			<div v-if="cliente_duplicado" class="mx-4 bg-white rounded p-4 border mt-4">
				<p class="font-medium">Dirección</p>						
				<p>{{cliente_duplicado.direccion_calle}} {{cliente_duplicado.direccion_exterior}}</p>
				<p>{{cliente_duplicado.direccion_interior}}</p>
				<p>{{cliente_duplicado.direccion_colonia}} {{cliente_duplicado.direccion_codigo}}</p>						
				<p>{{cliente_duplicado.direccion_estado}}</p>
			</div>

			<div v-if="cliente_duplicado && cliente_duplicado.cliente_proveedor.length > 0" class="hidden sm:block w-full text-left px-4 mt-4">		
				<div class="border boder-gray-300 border-b-0 rounded overflow-hidden mt-4 bg-white">
					<div class="overflow-scroll">
						<table class="w-full text-sm text-left ">
							<thead class="text-s border-b border-b-gray-200">																	
								<th scope="col" class="py-3 px-6">
									Proveedor
								</th>						
							</thead>
							<tbody v-for="cliente_proveedor in cliente_duplicado.cliente_proveedor" v-bind:key="cliente_proveedor.id" class="text-s border-b">	
								<td scope="row" class="py-3 px-6 whitespace-nowrap">
									{{cliente_proveedor.proveedor.nombre}}
								</td>
							</tbody>
						</table>
					</div>		
				</div>
			</div>

			<div v-if="cliente_duplicado && cliente_duplicado.pedidos.length > 0" class="hidden sm:block w-full text-left px-4 mt-4 pb-4">		
				<div class="border boder-gray-300 border-b-0 rounded overflow-hidden mt-4 bg-white">
					<div class="overflow-scroll">
						<table class="w-full text-sm text-left ">
							<thead class="text-s border-b border-b-gray-200">													
								<th scope="col" class="py-3 px-6">
									Fecha
								</th>
								<th scope="col" class="py-3 px-6">
									Proveedor
								</th>						
							</thead>
							<tbody v-for="pedido in cliente_duplicado.pedidos" v-bind:key="pedido.id" class="text-s border-b">						
								<td class="py-3 px-6 whitespace-nowrap">
									{{pedido.created_at.split("T")[0]}}
								</td>
								<td scope="row" class="py-3 px-6 whitespace-nowrap">
									{{pedido.proveedor.nombre}}
								</td>
							</tbody>
						</table>
					</div>		
				</div>
			</div>

			<empty v-if="cliente_duplicado && cliente_duplicado.pedidos.length == 0" :texto=" 'Este cliente no tiene pedidos' "> </empty>			
		</div>

		<div class="p-4 flex-none border-t border-t-gray-300">
			<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4" @click="combinarClientes()">
				Combinar clientes
			</button>
		</div> 
		
	</div>
</template>

<script>
	import { supabase } from "../supabase"	
	import { onMounted, ref, watch, toRaw } from "vue"
	import { utils } from "./utils/utils.js"		
	import { useLoading } from 'vue-loading-overlay'
	// import { useToast } from 'vue-toast-notification';
	
	const loading = useLoading({
		color: "#428b74",
		loader: "spinner"
	});


	export default {
		name: 'Proveedor Cliente',
		setup() {	
			const cliente = ref("")						
			const cliente_duplicado = ref("")						
			const clientes = ref(null)													

			watch(cliente, async (newCliente) => {		
				if (cliente_duplicado.value && newCliente.id == cliente_duplicado.value.id) {
					cliente_duplicado.value = null
				}
			})

			async function getClientes() {
				let { data, error, status } = await supabase
				.from("clientes")
				.select('*, cliente_producto(*), cliente_producto_precio(*), cliente_proveedor(*, proveedor(id, nombre)), pedidos(*, proveedor(id, nombre))')								
				.order('nombre', { ascending: true })				

				if (error && status !== 406) {
					throw error
				}

				if (data) {   
					clientes.value = data.sort((a,b) => (a.nombre.toLowerCase() > b.nombre.toLowerCase()) ? 1 : ((b.nombre.toLowerCase() > a.nombre.toLowerCase()) ? -1 : 0))
				} else if (error) {  
					console.log(error)
					alert(error)     
				}   
			}

			async function combinarClientes() {				
				let loader = loading.show()
				await utils.updateCliente(cliente_duplicado.value.id, { redirect_to: cliente.value.id })				
				//If a cliente logs into an old link, show the redirect and also show a toast alerting to the new link

				let duplicate_cliente_proveedores = toRaw(cliente_duplicado.value.cliente_proveedor).map( cP => {
					cP.proveedor = cP.proveedor.id
					cP.cliente = cliente.value.id
					return cP
				})

				let duplicate_cliente_pedidos = toRaw(cliente_duplicado.value.pedidos).map( p => {
					p.cliente = cliente.value.id
					p.proveedor = p.proveedor.id
					return p
				})

				let duplicate_cliente_productos = toRaw(cliente_duplicado.value.cliente_producto).map( cP => {
					cP.cliente = cliente.value.id					
					return cP
				})

				let duplicate_cliente_producto_precios = toRaw(cliente_duplicado.value.cliente_producto_precio).map( cPP => {
					cPP.cliente = cliente.value.id					
					return cPP
				})

				console.log('duplicate_cliente_proveedores', duplicate_cliente_proveedores)
				console.log('duplicate_cliente_pedidos', duplicate_cliente_pedidos)
				console.log('duplicate_cliente_productos', duplicate_cliente_productos)
				console.log('duplicate_cliente_producto_precios)', duplicate_cliente_producto_precios)	

				let existing_proveedores = cliente.value.cliente_proveedor.map( cP => cP.proveedor.id )
				console.log("existing_proveedores", existing_proveedores)

				let duplicate_cliente_proveedores_delete = duplicate_cliente_proveedores.filter( cP => existing_proveedores.find( eP => eP == cP.proveedor) )
				let duplicate_cliente_proveedores_keep = duplicate_cliente_proveedores.filter( cP => !existing_proveedores.find( eP => eP == cP.proveedor) )

				console.log('duplicate_cliente_proveedores', duplicate_cliente_proveedores_delete)
				console.log('non_duplicate_cliente_proveedores', duplicate_cliente_proveedores_keep)
				
				await upsert('cliente_proveedor', duplicate_cliente_proveedores_keep)
				await upsert('pedidos', duplicate_cliente_pedidos)
				await upsert('cliente_producto', duplicate_cliente_productos)
				await upsert('cliente_producto_precio', duplicate_cliente_producto_precios)	
				await multiDelete('cliente_proveedor', duplicate_cliente_proveedores_delete)	

				cliente.value = ""
				cliente_duplicado.value = ""

				await getClientes()		
				loader.hide()			
			}	

			async function multiDelete(model, datas) {
				for (const data of datas) {
					if (!data.id) {
						return
					}
					console.log(model, data.id)
					const { error } = await supabase
					.from(model)
					.delete()
					.eq('id', data.id)

					console.log(model, data, error)
				}
			}

			async function upsert(model, data) {
				let { error } = await supabase
				.from(model)
				.upsert(data)			   

				if (error) {
					console.log(`utils ${model}`, error)       
					throw error 
				} else {
					console.log(`done ${model}`)
					return
				}
			}

			onMounted(() => {					
				getClientes()							
			})

			return {     
				cliente,				
				cliente_duplicado,				
				clientes,								
				combinarClientes
			}
		},
	}
</script> 