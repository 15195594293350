<template>
	<div class="flex flex-col h-full">
		<div class="flex-auto overflow-scroll">
			<h3 class="text-2xl font-bold pt-4 px-4 flex content-center">  
				<div @click="utils.goBack(router, route.params.proveedor_id ? `/proveedor/${route.params.proveedor_id}/entradas` : '/admin/entradas')" class="pr-2 flex items-center cursor-pointer">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
						<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
					</svg>
				</div>

				Entrada
			</h3>

			<div v-if="!entrada" class="px-4 pt-4" >				
				<select v-model="proveedor" v-if="proveedores && proveedores.length > 0" id="countries" class="border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
					<option selected disabled value="">Escoger un proveedor</option>
					<option v-for="proveedor in proveedores" v-bind:key="proveedor.id" :value="proveedor">{{proveedor.nombre}}</option>			
				</select>				
			</div>

			<div class="w-full text-left p-4" v-if="productos && productos.length > 0">
				<div class="border boder-gray-300 overflow-hidden rounded bg-white">
					<div class="overflow-scroll">
						<table class="w-full text-sm text-left ">
							<thead class="text-s border-b border-b-gray-200">									
								<th scope="col" class="py-3 px-6">
									Producto
								</th>
								<th scope="col" class="py-3 px-6">
									Cantidad
								</th>							
							</thead>
							<tbody>
								<tr class="text-s bg-white border-b" v-for="producto in productos" v-bind:key="producto.nombre">								
									<td class="py-3 px-6 whitespace-nowrap">
										{{producto.nombre}}
									</td>
									<td class="py-3 px-6">
										<input v-if="!entrada" class="appearance-none border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="nombre" type="number" placeholder="0" min="0" v-model="producto.cantidad_entregada">
										<p v-if="entrada">{{producto.cantidad_entregada}}</p>
									</td>								
								</tr>              
							</tbody>
							<tfoot class="text-s text-gray-700 border-t">
								<tr>
									<th scope="col" class="py-3 px-6">
										Total
									</th>
									<th scope="col" class="py-3 px-6">
										{{productos.map( p => p.cantidad || 0).reduce((a, b) => a + b)}}
									</th>																	
								</tr>
							</tfoot>
						</table>
					</div>
				</div>						
			</div>
		</div>

		<div v-if="showAddButton" class="p-4 flex-none border-t border-t-gray-300">
			<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4" @click="execute">
				Crear entrada
			</button>
		</div> 
		
	</div>
</template>

<script>
	import { supabase } from "../supabase"
	import { useRoute, useRouter } from 'vue-router'
	import { onMounted, ref, computed } from "vue"
	import { useToast } from 'vue-toast-notification';
	import { useLoading } from 'vue-loading-overlay'
	import { utils } from './utils/utils.js'

	const loading = useLoading({
		color: "#428b74",
		loader: "spinner"
	});

	export default {
		name: 'Entrada',
		setup() {	
			const entrada = ref(null)
			const route = useRoute()		
			const router = useRouter()			
			const proveedor = ref(null)
			const proveedores = ref(null)
			const productosTodos = ref(null)
			const showAddButton = ref(false)

			const productos = computed( () => {
				if (entrada.value) {					
					return entrada.value.entrada_item.map( eI => {
						return {
							nombre: eI.producto.nombre,
							cantidad_entregada: eI.cantidad_entregada	
						}	
					})
				}

				if (!proveedor.value) {
					return []
				}				

				return productosTodos.value.filter( p => p.proveedor == proveedor.value.id )
			})

			async function getEntrada() {
				let { data, error, status } = await supabase
				.from("entrada")
				.select('*, proveedor(nombre), entrada_item( cantidad_entregada, producto (nombre) )')								
				.match({'id': route.params.entrada_id})
				.single()

				if (error && status !== 406) {
					throw error
				}

				if (data) {   
					console.log(data)
					entrada.value = data					
				} else if (error) {
				console.log(error)  
					alert(error)     
				}   
			}

			async function getProductos() {
				let { data, error, status } = await supabase
				.from("productos")
				.select('*')								

				if (error && status !== 406) {
					throw error
				}

				if (data) {   
					productosTodos.value = data
				} else if (error) {  
					alert(error)     
				}   
			}

			async function getProveedores() {
				let { data, error, status } = await supabase
				.from("proveedores")				
				.select('*')        
				.order('nombre', { ascending: true })  						

				if (error && status !== 406) {
					throw error
				}

				if (data) {   
					proveedores.value = data
					return data
				} else if (error) {  
					alert(error)     
				}   

				return
			}

			async function execute() {
				let loader = loading.show()
				let entrada = await crearEntrada()
				
				if (entrada) {
					crearLineItems(entrada)		
					.then( lineItems => {
						return updateInventory(lineItems)
					})					
					.then( () => {
						loader.hide()
						useToast().success('Entrada creado', { position: "top-right" });
						router.push(`/admin/entradas`)
					}) 
					.catch( (error) => {
						loader.hide()
						console.log(error)
						useToast().error('Error al crear entrada', { position: "top-right" });
					})
				}      
			}

			async function updateInventory(lineItems) {
				let payload = lineItems.map( lineItem => {
					let producto = productos.value.find( producto => { 
						console.log("producto", producto)
						return producto.id == lineItem.producto 
					})
					console.log(productos.value, lineItem, producto)
					let newCantidad = producto.cantidad + lineItem.cantidad_entregada
					return {
						id: producto.id,
						cantidad: newCantidad
					}
				}) 

				const { data, error } = await supabase.rpc('admin_inventory', {
					payload:payload
				});				
				
				if (data) {  
					return data
				} else if (error) {  
					throw error	
				}			
			}

			async function crearEntrada() { 
				if (!proveedor.value.id) {
					useToast().warning("Favor de escoger un proveedor", { position: "top-right" });
					return 
				}	

				let total = productos.value.map( producto => {
					return producto.cantidad_entregada || 0
				}).reduce((a, b) => a + b)				
				
				if (!total || total == 0 ) {
					useToast().warning("Debes de tener mínimo un producto en tu orden de compra", { position: "top-right" });
					return 
				}			

				let { data, error, status } = await supabase
				.from('entrada')
				.insert([{				
					"proveedor": proveedor.value.id,					
				}])

				if (error && status !== 406) {
					throw error
				}

				if (data && data.length > 0) {  
					return data[0]
				} else if (error) { 					
					throw error
				}     

				throw "Pedido no creado"
			}

			async function crearLineItems(entrada) {				
				let line_items = productos.value.filter( p => { return p.cantidad_entregada > 0}).map( (p) => {					
					return {
						"entrada": entrada.id,
						"producto": p.id,
						"cantidad_entregada": p.cantidad_entregada,
					}
				})					

				let { data, error, status } = await supabase
				.from('entrada_item')
				.insert(line_items)

				if (error && status !== 406) {
					throw error
				}

				if (data && data.length > 0) {  
					return line_items
				} else if (error) {  
					throw error
				}     
			}

			function change(producto, cantidad) {
				if (!producto.cantidad_entregada) {
					producto.cantidad_entregada = 0
				}

				if (producto.cantidad_entregada + cantidad >= 0 && producto.cantidad_entregada + cantidad <= producto.cantidad) {
					producto.cantidad_entregada = producto.cantidad_entregada + cantidad
				}
			}

			onMounted(() => {	
				getProveedores()				
				getProductos()	

				if (route.params.entrada_id) {
					getEntrada()
				}

				if (!route.params.proveedor_id) {
					showAddButton.value = true
				}
			})

			return { 
				route,
				router,
				productosTodos,
				productos,
				proveedores,
				proveedor,				
				execute,
				entrada,
				change,
				utils,
				showAddButton
			}
		},
	}
</script> 