<template>		
	<div>		
		<div v-if="!pedido.standalone && !pedido.cancelado" :class="[requiereSurtir(pedido) ? ( pedido.surtido ? 'border border-primary text-primary' : 'border border-yellow-500 text-yellow-500') : 'border text-gray-300 border-gray-300']" class="rounded-full text-center py-1 px-2">
			<p class="whitespace-nowrap">{{ requiereSurtir(pedido) ? ( pedido.surtido ? "Surtido" : "Pendiente") : "No requiere"}}</p>
		</div>

		<div v-if="pedido.standalone && !pedido.cancelado" class="rounded-full text-center py-1 px-2 border text-gray-300 border-gray-300">
			<p class="whitespace-nowrap">No requiere</p>
		</div>

	</div>
</template>

<script>
	import { utils } from '../utils/utils.js'

	export default {
		props: ['pedido', 'user'],

		setup(props) {		
			function requiereSurtir() {
				return utils.requireEntregaSurtir(props.pedido).surtir
			}

			return {
				requiereSurtir
			}
		}
	}
</script>