<template>

	<div class="flex flex-col h-full">
		<div class="block h-full overflow-y-scroll">
			<div class="flex-auto overflow-scroll w-full"> 
				<div class="flex justify-between pt-4 px-4 items-top">
					<div class="flex-none">
						<p class="text-2xl font-bold">
							Remisión #{{pedido.id}}
						</p>
						<p class="text-xl">
							{{pedido.created_at ? (new Date(pedido.created_at)).toLocaleDateString("en-GB", { timeZone: 'America/Mexico_City' }) : ""}}
						</p>
						<p class="text-xl">
							{{pedido.cliente ? pedido.cliente.nombre : ""}}
						</p>
					</div>

					<div class="text-left" style="height: 88px; width: 88px">
						<qr :text="enlace" size=100 color="#000000" bg-color="#ffffff" error-level="L"></qr>
					</div>
				</div>				

				<div class="border boder-gray-300 rounded mt-4 mb-4 mx-4">
					<div  class="overflow-scroll">
						<table class="w-full text-sm text-left ">
							<thead class="text-s border-b border-b-gray-200">
								<tr>
									<th scope="col" class="py-3 px-6 w-4/12">
										Dirección de entrega
									</th>													
								</tr>
							</thead>
							<tbody class="">
								<tr class="text-s">
									<th v-if="pedido && pedido.datos_entrega" scope="row" class="py-3 px-6 font-medium whitespace-nowrap">
										<p>{{pedido.datos_entrega.direccion.calle}} {{pedido.datos_entrega.direccion.exterior}}</p>						
										<p>{{pedido.datos_entrega.direccion.colonia}} {{pedido.datos_entrega.direccion.codigo}}</p>						
										<p>{{pedido.datos_entrega.direccion.estado}}</p>
									</th>														
								</tr>              
							</tbody>
						</table>
					</div>

					<div v-if="pedido.cliente && Object.keys(pedido.cliente.horario).filter( d => pedido.cliente.horario[d].start ).length > 0" class="overflow-scroll">
						<table class="w-full text-sm text-left ">
							<thead class="text-s border-b border-t border-b-gray-200">
								<tr>
									<th scope="col" class="py-3 px-6 w-3/12">
										Horario
									</th>												
								</tr>
							</thead>
							<tbody class="">
								<tr class="text-s">
									<th scope="row" class="py-3 px-6 font-medium whitespace-nowrap">
										<div v-for="day in Object.keys(pedido.cliente.horario)" v-bind:key="day">
											<p v-if="pedido.cliente.horario[day].start">
												{{day}} de {{pedido.cliente.horario[day].start}} a {{pedido.cliente.horario[day].end}}
											</p>
										</div>
									</th>														
								</tr>              
							</tbody>
						</table>
					</div>

					<div v-if="pedido.instrucciones"  class="overflow-scroll">
						<table class="w-full text-sm text-left ">
							<thead class="text-s border-b border-t border-b-gray-200">
								<tr>
									<th scope="col" class="py-3 px-6 w-4/12">
										Instrucciones
									</th>													
								</tr>
							</thead>
							<tbody class="">
								<tr class="text-s">
									<th scope="row" class="py-3 px-6 font-medium whitespace-nowrap">
										{{pedido.instrucciones}}
									</th>														
								</tr>              
							</tbody>
						</table>
					</div>

					<div class="overflow-scroll">
						<table class="w-full text-sm text-left ">
							<thead class="text-s border-b border-t border-b-gray-200 border-t-gray-200">
								<tr>
									<th scope="col" class="py-3 px-6 w-4/12">
										Nombre
									</th>
									<th v-if="!pedido.standalone" scope="col" class="py-3 px-6 w-4/12">
										Proveedor
									</th>							
									<th scope="col" class="py-3 px-6 w-4/12">
										Cantidad
									</th>							
								</tr>
							</thead>
							<tbody>
								<tr class="text-s border-b border-b-gray-200" v-for="line_item in pedido.line_items" v-bind:key="line_item.id">				
									<td class="py-3 px-6 whitespace-nowrap ">
										<div class="flex items-center">
											<div v-if="line_item.producto.foto" class="rounded overflow-hidden w-20 mr-4">
												<img :src="line_item.producto.foto" class="">
											</div>
											{{line_item.producto.nombre}}
										</div>
									</td>
									<th v-if="!pedido.standalone" scope="row" class="py-3 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
										{{line_item.producto.proveedor.nombre}}
									</th>

									<td class="py-3 px-6">
										{{line_item.cantidad}}
									</td>							
								</tr>              
							</tbody>
							<tfoot class="text-s text-gray-700 dark:text-gray-400 ">									
								<tr>
									<th scope="col" class="py-3 px-6 w-4/12">
										Total
									</th>
									<th v-if="!pedido.standalone" scope="col" class="py-3 px-6 w-4/12">								
									</th>						
									<th v-if="pedido.id" scope="col" class="py-3 px-6 w-4/12">
										{{pedido.line_items.map( l => {return (l.cantidad)} ).reduce((a, b) => a + b) }}
									</th>							
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import qr from 'vue-qr-generator'
	import { supabase } from "../supabase"
	import { useRoute } from "vue-router"	
	import { computed, onMounted, ref } from "vue"
	
	export default {
		name: 'Order',
		components: {
			qr: qr
		},
		setup() {			
			const route = useRoute()
			const pedido = ref({})					

			async function getPedido() {				
				let { data, error } = await supabase
				.from("pedidos")
				.select('id, standalone, datos_entrega, instrucciones, cliente (id, nombre, horario), created_at, remision_firmada, pedido_factura(*), line_items!inner(id, pedido, cantidad, cantidad_entregada, precio, producto (id, nombre, precio, foto, proveedor (id, nombre)))')
				.eq('id', route.params.orden_id)
				.single()

				if (data) {        					
					pedido.value = data
				} else if (error) { 					
					alert(error)     
				}   
			} 

			onMounted( async () => {
				getPedido()
			})

			const enlace = computed( () => {        
				return pedido.value.id ? `https://www.pickpack.mx/cliente/${pedido.value.cliente.id}/orden/${pedido.value.id}` : ""
			})

			return {      
				pedido,
				enlace
			}
		},
	}
</script> 