<template>
	<div class="flex flex-col h-full">
		<div class="flex-auto overflow-scroll">
			<h3 class="text-2xl font-bold pt-4 px-4">      		
				Payouts
			</h3>	

			<empty v-if="payouts && payouts.length == 0" :texto="'Aún no tienes un payout.'"> </empty>

			<div v-if="payouts && payouts.length > 0" class=" w-full text-left p-4 ">
				<div class="border boder-gray-300 rounded bg-white x2">
					<div class="overflow-scroll">
						<table class="w-full text-sm text-left ">
							<thead class="text-s border-b border-b-gray-200">						
								<th scope="col" class="py-3 px-6">
									Periodo
								</th>
								<th v-if="admin" scope="col" class="py-3 px-6">
									Proveedor
								</th>
								<th scope="col" class="py-3 px-6">
									Venta total
								</th>					
								<th scope="col" class="py-3 px-6">
									Comisión (c/IVA)
								</th>	
								<th scope="col" class="py-3 px-6">
									Payout
								</th>																	
							</thead>
							<tbody>
								<tr class="text-s border-b" v-for="payout in payouts" v-bind:key="payout.id" >
									<td class="py-3 px-6 underline">								
										<router-link :to="getLink(payout)">
											{{payout.mes}}/{{payout.año}}
										</router-link> 										
									</td>
									<td v-if="admin" class="py-3 px-6">							
										{{payout.proveedor.nombre}}
									</td>
									<td class="py-3 px-6 ">							
										${{Math.round(payout.total * 100)/100}}
									</td>
									<td class="py-3 px-6 ">							
										${{Math.round(payout.comision * 1.16 * 100)/100}}
									</td>	
									<td class="py-3 px-6 ">							
										${{Math.round( (payout.total - (payout.comision * 1.16)) * 100)/100}}
									</td>																															
								</tr>              
							</tbody>
							<tfoot class="font-bold">
								<tr>
									<td class="py-3 px-6">								
										Total
									</td>
									<td  v-if="admin" class="py-3 px-6">							
										
									</td>
									<td class="py-3 px-6 ">							
										${{Math.round((payouts.map( p => p.total)).reduce((a, b) => a + b) * 100)/100}}
									</td>																
									<td class="py-3 px-6 ">										
										${{Math.round((payouts.map( p =>(p.comision * 1.16))).reduce((a, b) => a + b) * 100)/100}}
									</td>
									<td class="py-3 px-6 ">										
										${{Math.round((payouts.map( p => p.total - (p.comision * 1.16))).reduce((a, b) => a + b) * 100)/100}}
									</td>												
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div v-if="admin" class="p-4 flex-none border-t border-t-gray-300">							
			<router-link :to="'/admin/payout'">
				<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4">
					Hacer payout
				</button>
			</router-link>
		</div>
	</div>
</template>

<script>
	import { useRoute } from 'vue-router'	
	import { onMounted, ref } from "vue"
	import { utils } from './utils/utils.js'

	export default {		
		setup() {
			const route = useRoute()
			const payouts = ref([])
			const admin = ref(false)

			onMounted( async () => {
				if (route.name == "Admin_Payouts") {
					admin.value = true
					payouts.value = await utils.getAllPayouts()					
				} else {
					payouts.value = await utils.getPayouts(route.params.proveedor_id)					
				}					
			})	

			function getLink(payout) {
				if (route.name == "Admin_Payouts") {
					return `/admin/payout/${payout.id}`
				}

				return `/proveedor/${route.params.proveedor_id}/payout/${payout.id}`
			}

			return {
				route,
				payouts,
				getLink,
				admin
			}
		}
	}
</script>