<template>
	<div class="h-full">
		<div v-if="cliente && cliente.redirect_to" class="p-4">				
			<div class="border boder-gray-300 rounded cursor-pointer bg-white" @click="copiarEnlace()">				
				<p class="p-4 text-center">		
					¡Ya tienes un nuevo enlace! Copia este enlace para poder hacer tus pedidos:
					<span class="underline">{{ `https://pickpack.mx/cliente/${cliente.redirect_to}` }}</span>
				</p>
			</div>
		</div>

		<div class="flex flex-col h-full" v-if="cliente && !cliente.redirect_to">
			<div class="flex-auto overflow-scroll">
				<h3 class="text-2xl font-bold pt-4 px-4">      	
					Ordenes
				</h3>
				<div v-if="pedidos && pedidos.length > 0" class="pb-4 pt-4"> 
					<inbox class="" :user="'cliente'" :ordenes="pedidos" :fields="{proveedor: true, cliente: false, surtir: false, aprobar: false, facturar: true, entregar: true, pago: showPayments}"></inbox>
				</div>
				<div v-if="pedidos && pedidos.length == 0" class="pb-4"> 		
					<empty :texto="'¡No tienes ordenes pendientes!'"></empty>
				</div>
			</div>
			<div class="p-4 flex-none border-t border-t-gray-300">
				<router-link :to=' "/cliente/" + cliente.id + "/orden" '>
					<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4">
						Agregar orden
					</button>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>	
	import { useRoute } from 'vue-router'
	import { onMounted, ref } from "vue"
	import { store } from '../store.js'
	import { utils } from './utils/utils.js'
	import { useLoading } from 'vue-loading-overlay'
	import { useToast } from 'vue-toast-notification';

	export default {
		name: 'Order',
		setup() {
			const route = useRoute()			   
			const pedidos = ref(null)
			const cliente = ref({})			
			const showPayments = ref(false)   
			
			const loading = useLoading({
				color: "#428b74",
				loader: "spinner"
			});

			onMounted( async () => {
				let loader = loading.show()
				
				utils.getCliente(route.params.cliente_id)
				.then( data => {
					cliente.value = data					
					store.setNombre(data.nombre)
				})
				.catch( () => {					
					alert('Hubo un error, favor de intertarlo otra vez.')
				}) 

				utils.getPedidosPorCliente(route.params.cliente_id)
				.then( data => {					
					loader.hide()
					pedidos.value = data
					showPayments.value = data.filter( p => p.proveedor.payments == true).length > 0
				})
			})

			function copiarEnlace() {			
				navigator.clipboard.writeText(`https://pickpack.mx/cliente/${cliente.value.redirect_to}`);
				useToast().success('Enlace copiado', { position: "top-right" });
			}

			return {      				
				pedidos,
				cliente,
				copiarEnlace,
				showPayments
			}
		},
	}
</script> 