<template>		
	<div>		
		<div v-if="!props.pedido.standalone && !props.pedido.cancelado" :class="[props.pedido.require_entrega ? ( props.pedido.envios.filter(e => e.status == 'exitoso').length > 0 ? 'border border-primary text-primary' : 'border border-yellow-500 text-yellow-500') : 'border text-gray-300 border-gray-300']" class="rounded-full text-center py-1 px-2">
			<p class="whitespace-nowrap">{{ props.pedido.require_entrega ? ( props.pedido.envios.filter(e => e.status == 'exitoso').length > 0 ? "Entregado" : "Pendiente") : "No requiere"}}</p>
		</div>

		<div v-if="props.pedido.standalone && !props.pedido.cancelado" :class="[props.pedido.require_entrega ? (props.pedido.entregado ? 'border border-primary text-primary' : 'border border-yellow-500 text-yellow-500') : 'border text-gray-300 border-gray-300']" class="rounded-full text-center py-1 px-2">
			<p class="whitespace-nowrap">{{ props.pedido.require_entrega ? (props.pedido.entregado ? "Entregado" : "Pendiente") : "No requiere"}}</p>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['pedido'],

		setup(props) {					
			return {
				props
			}
		}
	}
</script>