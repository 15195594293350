<template>
	<div class="flex flex-col h-full">
		<div class="flex-auto overflow-scroll">
			<div v-if="pedido">
				<div class="flex flex-row items-center pt-4 px-4 justify-between">
					<div class="flex content-center">					
						<h3 class="text-2xl font-bold">
							Orden #{{pedido.id}}
						</h3>
					</div>	
					<status :pedido="pedido" :user="'cliente'"></status>
				</div>

				<div class="flex flex-row items-center pt-4 px-4 justify-between">
					<h3 class="text-xl font-semibold">
						{{pedido.proveedor.nombre}}
					</h3>

					<h3 class="text-xl font-semibold">
						{{pedido.created_at ? (new Date(pedido.created_at)).toLocaleDateString("en-GB", { timeZone: 'America/Mexico_City' }) : ""}}
					</h3>
				</div>			

				<div class="w-full text-left p-4 pt-0">
					<div class="border boder-gray-300 rounded mt-4 bg-white">
						<div class="overflow-scroll">
							<table class="w-full text-sm text-left ">
								<thead class="text-s border-b border-b-gray-200">
									<th scope="col" class="py-3 px-6 w-3/12">
										Producto
									</th>
									<th scope="col" class="py-3 px-6 w-3/12">
										Cantidad
									</th>
									<th scope="col" class="py-3 px-6 w-3/12">
										Precio
									</th>
									<th scope="col" class="py-3 px-6 w-3/12">
										Total
									</th>							
								</thead>
								<tbody>
									<tr class="text-s dark:border-gray-700 border-b" v-for="line_item in pedido.line_items" v-bind:key="line_item.id">												
										<td class="py-3 px-6 whitespace-nowrap">
											{{line_item.producto.nombre}}
										</td>
										<td class="py-3 px-6">
											{{line_item.cantidad}}
										</td>
										<td class="py-3 px-6">
											${{line_item.precio}}
										</td>
										<td class="py-3 px-6">
											${{line_item.precio * line_item.cantidad}}					
										</td>
									</tr>              
								</tbody>
								<tfoot class="text-s text-gray-700 dark:text-gray-400">	
									<tr v-if="pedido.envio" >
										<th scope="col" class="px-6 pt-3 pb-1 font-medium">
											Producto
										</th>
										<th scope="col" class="py-3 px-6">

										</th>
										<th scope="col" class="px-6 pt-3 pb-1 font-medium">

										</th>
										<th scope="col" class="px-6 pt-3 pb-1 font-medium">
											${{pedido.line_items.map( l => {return l.precio * l.cantidad} ).reduce((a, b) => a + b) }}
										</th>
									</tr>
									<tr v-if="pedido.envio">
										<th scope="col" class="px-6 font-medium">
											Envio
										</th>
										<th scope="col" class="py-3 px-6">

										</th>
										<th scope="col" class="px-6 font-medium">

										</th>
										<th scope="col" class="px-6 font-medium">
											${{pedido.envio}}
										</th>
									</tr>
									<tr>
										<th scope="col" class="py-3 px-6">
											Total
										</th>
										<th scope="col" class="py-3 px-6">
											{{pedido.line_items.map( l => {return l.cantidad} ).reduce((a, b) => a + b) }}
										</th>
										<th scope="col" class="py-3 px-6">

										</th>
										<th scope="col" class="py-3 px-6">
											${{pedido.line_items.map( l => {return l.precio * l.cantidad} ).reduce((a, b) => a + b) + (pedido.envio || 0) }}
										</th>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>

				<div class="text-left p-4 pt-0 flex flex-row w-full overflow-scroll gap-8">
					<div v-for="(pf, index) in pedido.pedido_factura" v-bind:key="pf.id" class="flex-none w-72 border boder-gray-300 rounded overflow-hidden bg-white">
						<div class="text-sm font-bold py-3 px-6 bg-white">
							Facutra {{index +1}}
						</div>

						<a :href="pf.url" target="_blank">				
							<embed :src="pf.url" class="w-72 h-full object-cover">				
							</a>
						</div>


						<div v-for="(evidencia, index) in evidencias" v-bind:key="evidencia.envio" class="flex-none w-72 border boder-gray-300 rounded overflow-hidden bg-white">	
							<div class="text-sm font-bold py-3 px-6">
								Evidencia {{index +1}}
							</div>
							<a :href="evidencia.evidencia" target="_blank">
								<img :src="evidencia.evidencia" class="w-72 h-full object-cover">
							</a>					
						</div>
					</div>	
				</div>			
			</div>	

		<!-- 	<div v-if="pedido" class="p-4 flex-none">
				<div class="border boder-gray-300 rounded cursor-pointer bg-white" @click="copiarEnlace()">				
					<p class="p-4 text-center">		
						Copia este enlace para tener tus datos ya llenos en tu próximo pedido:
						<span class="underline">{{ `https://pickpack.mx/shop/${pedido.proveedor.shop_name}?cliente=${pedido.cliente.id}`}}</span>
					</p>
				</div>
			</div> -->

		</div>
	</template>

	<script>	
		import { utils } from "./utils/utils.js"
		import { useRoute } from "vue-router"	
		import { onMounted, ref, computed } from "vue"	
		import { useToast } from 'vue-toast-notification';

		export default {
			name: 'Order',
			setup() {						
				const route = useRoute()			
				const pedido = ref(null)			

				const evidencias = computed( () => {
					return pedido.value.envios.filter( e => e.status == 'exitoso').flatMap( e => {
						if (!e.evidencia) {
							return []
						}
						return e.evidencia.flatMap( ev => {
							return {
								envio: e.id,
								evidencia: ev
							}
						})
					})
				})			

				function copiarEnlace() {
					navigator.clipboard.writeText(`${window.location.host}/shop/${pedido.value.proveedor.shop_name}?cliente=${pedido.value.cliente.id}`);
					useToast().success('Enlace copiado', { position: "top-right" });					
				}

				onMounted( async () => {
					utils.getPedido(route.params.orden_id)
					.then( data => {
						pedido.value = data
					})
					.catch( () => {
						alert('Hubo un error, favor de intentarlo otra vez')
					})
				})			

				return {      
					pedido,														
					evidencias,
					copiarEnlace
				}
			},
		}
	</script> 