<template>
	<div class="flex flex-col h-full">
		<div class="flex-auto overflow-scroll">
			<h3 class="text-2xl font-bold pt-4 px-4">      		
				Direcciones
			</h3>	

			<empty v-if="direcciones && direcciones.length == 0" :texto="'Crea tu primera dirección usando el botón abajo'"> </empty>

			<div v-if="direcciones && direcciones.length > 0" class=" w-full text-left p-4 ">
				<div class="border boder-gray-300  border-b-0 rounded bg-white overflow-hidden">
					<div class="overflow-scroll">
						<table class="w-full text-sm text-left ">
							<thead class="text-s border-b border-b-gray-200">								
								<th scope="col" class="py-3 px-6">
									Nombre
								</th>
								<th scope="col" class="py-3 px-6">
									Dirección
								</th>						
							</thead>
							<tbody>
								<tr class="text-s border-b" v-for="direccion in direcciones" v-bind:key="direccion.id">
									<td class="py-3 px-6 underline">							
										<router-link :to="`/proveedor/${route.params.proveedor_id}/direccion/${direccion.id}`">		
											{{direccion.nombre }}
										</router-link>		
									</td>
									<td class="py-3 px-6">										
										{{direccion.direccion_calle}} {{direccion.direccion_exterior}}, {{direccion.direccion_colonia}} , {{direccion.direccion_codigo}}, {{direccion.direccion_estado}}

									</td>
								</tr>              
							</tbody>
						</table>
					</div>
				</div>				
			</div>
		</div>
		<div v-if="route.params.proveedor_id" class="p-4 flex-none border-t border-t-gray-300">
			<router-link :to='`/proveedor/${route.params.proveedor_id}/direccion`'>
				<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4">
					Agregar dirección
				</button>
			</router-link>
		</div>
	</div>
</template>

<script>
	import { useRoute } from 'vue-router'	
	import { onMounted, ref } from "vue"
	import { utils } from './utils/utils.js'

	export default {		
		setup() {
			const route = useRoute()
			const direcciones = ref([])			

			onMounted( async () => {
				direcciones.value = await utils.getDirecciones(route.params.proveedor_id)														
			})				

			return {
				route,
				direcciones		
			}
		}
	}
</script>