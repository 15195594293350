<template class="h-full">


	<div class="overflow-scroll h-full">
		<header>
			<nav id="navbar" class="fixed inset-x-0 z-20 w-full border-b border-gray-100 bg-white/80 bg-white dark:border-gray-700/30 dark:bg-gray-900/80">
				<div class="mx-auto px-4 sm:px-12 xl:max-w-6xl xl:px-0">
					<div class="relative flex flex-wrap items-center justify-between gap-6 lg:gap-0 py-4">
						<div class="relative z-20 flex w-full justify-between md:px-0 lg:w-max">
							<a href="#home" aria-label="ampire logo" class="nav-link flex items-center space-x-2 text-xl font-semibold">                   
								PickPack
							</a>							
						</div>
					</div>
				</div>
			</nav>
		</header>

		<section id="home" class="pt-32 sm:pt-40 md:pt-48">
			<div class="mx-auto px-4 sm:px-12 xl:max-w-6xl xl:px-0">
				<div class="relative">
					<h1 class="text-center text-5xl font-bold text-primary dark:text-white sm:text-6xl lg:text-left lg:text-7xl">
						Pick, pack, deliver												
					</h1>

					<div class="relative items-center gap-12 lg:flex">						
						<div class="text-center sm:mx-auto w-full md:mt-12  lg:mt-0 lg:mr-auto  lg:text-left">
							<p class="mt-12 text-lg text-gray-600 dark:text-gray-300 sm:text-xl">La mejor plataforma para proveedores de la industria de hospitalidad. Únete y disfruta de almacenamiento sin problemas, una plataforma sencilla de gestión de pedidos y entregas ultra-rápidas. Da el primer paso para desbloquear todo el potencial de tu pequeña marca en el espacio de la hospitalidad.</p>
							<div action="" class="mt-12">
								<div class="relative flex items-center rounded-full border border-primary/20 bg-white p-1 px-2 shadow-md focus-within:ring-2 dark:border-white/10 dark:bg-dark md:p-2 lg:pr-3">
									<div class="py-3 pl-4 lg:pl-5">
										<svg class="m-auto h-6 w-6 fill-gray-500 dark:fill-gray-400"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
											<path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
										</svg>
									</div>
									<input v-model="email" aria-label="your email" autocomplete="email" placeholder="Tu correo electrónico" class="w-full rounded-full bg-transparent p-4 placeholder-gray-600 outline-none dark:text-white dark:placeholder-white" type="email" />
									<div class="md:pr-1.5 lg:pr-0">
										<button @click="sendMail(email)" type="button" title="Start buying" class="rounded-full bg-primary relative ml-auto h-12 w-16 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 active:duration-75 active:before:scale-95 dark:before:bg-primaryLight sm:w-auto sm:px-6">
											<span class="relative hidden w-max font-semibold text-white dark:text-gray-900 md:block"> ¡Empieza! </span>
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="relative mx-auto h-6 w-6 text-white dark:text-gray-900 md:hidden">
												<path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
											</svg>
										</button>
									</div>
								</div>
							</div>							
						</div>						
					</div>
					<!-- <div class="mt-12 border-y border-gray-100 py-8 text-center dark:border-gray-800 md:py-12 xl:mt-20">
						<a href="customers.html" class="text-sm font-semibold tracking-wider text-gray-800 dark:text-white">Algunos de nuestros clientes:</a>
						<div class="mt-8 flex flex-row justify-center gap-2 lg:gap-8 sm:justify-between h-32">
							<div class="flex items-center">
								<img class="h-full" src="https://cdn.shopify.com/s/files/1/0359/0874/7323/files/Vinoschidos_Elementos_byFAENA-01_c210c1a1-c253-4728-8482-fcdcc37ee8a6.png?v=1665852151" loading="lazy" alt="Vinos Chidos" width="" height="" /></div>
							<div class="flex items-center">
								<img class="" src="https://kpjyipdfykgbsoemuxib.supabase.co/storage/v1/object/public/misc/ROSADITO%20LOGO.png" loading="lazy" alt="Rosadito" width="" height="" /></div>
							<div class="flex items-center">
								<div class="h-full w-full bg-cover" style="background-image:url('https://kpjyipdfykgbsoemuxib.supabase.co/storage/v1/object/public/misc/gluglu_logocurvas-06.png')"></div>
							</div>							
						</div>
					</div> -->
				</div>
			</div>
		</section>



		<section id="company" class="pt-16 md:pt-32">
			<div class="mx-auto px-4 sm:px-12 xl:max-w-6xl xl:px-0">
				<div class="items-center justify-center space-y-6 md:flex md:gap-6 md:space-y-0">
					<div class="md:w-1/2 lg:w-3/5">
						<img class="h-full rounded-3xl object-cover md:-ml-8" src="https://kpjyipdfykgbsoemuxib.supabase.co/storage/v1/object/public/misc/envio.JPG" alt="tailus components" loading="lazy" width="1865" height="1750" />
					</div>
					<div class="md:w-1/2">
						<span class="text-sm font-semibold uppercase tracking-widest text-primary dark:text-secondaryLight">Nuestra historia</span>
						<h2 class="my-8 text-4xl font-bold text-gray-900 dark:text-white lg:text-5xl">Experiencia de primera mano</h2>
						<!-- <p class="mb-4 text-gray-600 dark:text-gray-300">Asperiores nemo possimus nesciunt dicta veniam aspernatur quam mollitia.</p> -->
						<p class="text-gray-600 dark:text-gray-300">PickPack nació de las dificultades que tuvimos al hacer crecer nuestros propios negocios en el ámbito de la hospitalidad: desde gestionar inventarios hasta procesar pedidos y garantizar entregas puntuales. Identificamos áreas de oportunidad para facilitar la vida tanto del proveedor como del cliente. Comenzamos ayudando a que nuestros negocios crecieran y ahora queremos ayudar a que el tuyo también crezca.</p>
					</div>
				</div>
			</div>
		</section>

		<section id="features" class="relative mb-32 pt-32">

			<div class="relative mx-auto px-4 sm:px-12 xl:max-w-6xl xl:px-0">
				<div class="text-center">
					<h2 class="text-4xl font-bold text-gray-800 dark:text-white xl:text-5xl">Un enfoque basado en la tecnología.</h2>
					<p class="mx-auto mt-6 text-gray-700 dark:text-gray-300 md:w-3/4 lg:w-3/5">La tecnología es parte de nuestro ADN. Desde el inventario hasta la entrega, buscamos la manera en que la tecnología puede eficientizar procesos.</p>
				</div>
				<div class="mt-16 grid gap-8 sm:mx-auto sm:w-2/3 md:w-full md:grid-cols-2 lg:grid-cols-3">
					<div class="rounded-3xl border border-gray-100 bg-white p-8 py-12 shadow-2xl shadow-gray-600/10 dark:border-gray-700 dark:bg-gray-800 dark:shadow-none sm:p-12">
						<div class="space-y-12 text-center">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mx-auto w-24 h-24">
								<path stroke-linecap="round" stroke-linejoin="round" d="m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
							</svg>
							<!-- <img src="https://cdn-icons-png.flaticon.com/512/584/584796.png" class="mx-auto h-14 w-auto" width="512" height="512" alt="burger illustration" /> -->
							<div class="space-y-6">
								<h3 class="text-2xl font-semibold text-gray-800 transition dark:text-white">Almacenamiento</h3>
								<p class="text-gray-600 dark:text-gray-300 text-left">Ofrecemos una instalación de almacenamiento donde manejamos tus productos con el cuidado necesario. Nuestros procesos garantizan que tu inventario esté actualizado, accesible y listo para ser recogido, empaquetado y enviado en cualquier momento.</p>								
							</div>
						</div>
					</div>
					<div class="rounded-3xl border border-gray-100 bg-white p-8 py-12 shadow-2xl shadow-gray-600/10 dark:border-gray-700 dark:bg-gray-800 dark:shadow-none sm:p-12">
						<div class="space-y-12 text-center">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mx-auto w-24 h-24">
								<path stroke-linecap="round" stroke-linejoin="round" d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25" />
							</svg>
							<!-- <img src="https://cdn-icons-png.flaticon.com/512/6106/6106288.png" class="mx-auto h-14 w-auto" width="512" height="512" alt="burger illustration" /> -->
							<div class="space-y-6">
								<h3 class="text-2xl font-semibold text-gray-800 transition dark:text-white">Gestión de pedidos</h3>
								<p class="text-gray-600 dark:text-gray-300 text-left">Administra tus pedidos al por mayor sin esfuerzo con nuestra plataforma fácil de usar. Desde la creación del pedido hasta el seguimiento del envío, te tenemos cubierto. Ten visibilidad en tiempo real de tus pedidos, niveles de inventario y estado de entrega.</p>								
							</div>
						</div>
					</div>
					<div class="rounded-3xl border border-gray-100 bg-white p-8 py-12 shadow-2xl shadow-gray-600/10 dark:border-gray-700 dark:bg-gray-800 dark:shadow-none sm:p-12">
						<div class="space-y-12 text-center">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mx-auto w-24 h-24">
								<path stroke-linecap="round" stroke-linejoin="round" d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
							</svg>
							<!-- <img src="https://cdn-icons-png.flaticon.com/512/4727/4727266.png" class="mx-auto h-14 w-auto" width="512" height="512" alt="burger illustration" /> -->
							<div class="space-y-6">
								<h3 class="text-2xl font-semibold text-gray-800 transition dark:text-white">Entregas rápidas</h3>
								<p class="text-gray-600 dark:text-gray-300 text-left">En el mundo acelerado de la hospitalidad, el tiempo es crucial. Con entregas al día siguiente o el mismo día, puedes asegurarte de que tus clientes nunca se queden sin stock de tus productos. Con PickPack, puedes superar las expectativas del cliente y construir relaciones duraderas.</p>								
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="relative before:absolute before:inset-0 before:h-px before:w-96 before:bg-gradient-to-r before:from-yellow-300 before:via-pink-400 before:to-transparent after:absolute after:inset-0 after:ml-auto after:mt-auto after:h-px after:w-96 after:bg-gradient-to-l after:from-yellow-300 after:via-pink-400 after:to-transparent">
            <div class="border-y border-gray-200 bg-gray-100 dark:border-gray-700 dark:bg-darker">
                <div class="relative mx-auto px-6 md:max-w-full md:px-12 lg:max-w-6xl xl:px-0">
                    <div class="items-end justify-between md:flex">
                        <div class="h-max py-16 md:w-6/12 xl:w-5/12">
                            <div class="text-center md:text-left">
                                <h2 class="text-3xl font-bold text-gray-800 dark:text-white md:w-max md:text-4xl xl:text-5xl">
                                    El primero paso <br />
									para crecer
                                </h2>
                                <p class="mb-8 mt-6 text-gray-600 dark:text-gray-300">¿Listo para revolucionar tu experiencia logística? Danos tu correo y alguien de nuestro equipo estará en contacto para resovler todas tus dudas.</p>
                                <form action="" class="mt-12">
                                    <div class="relative flex items-center rounded-full border border-primary/20 bg-white p-1 px-2 shadow-md focus-within:ring-2 dark:border-white/10 dark:bg-dark dark:text-white md:p-2 lg:pr-3">
                                        <div class="py-3 pl-4 lg:pl-5">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="m-auto h-6 w-6 fill-gray-500 dark:fill-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                            </svg>
                                        </div>
                                        <input autocomplete="email" aria-label="your email" placeholder="Your mail address" class="w-full rounded-full bg-transparent p-4 placeholder-gray-600 outline-none dark:placeholder-white" type="email" />
                                        <div class="md:pr-1.5 lg:pr-0">
                                            <button @click="sendMail(email)" type="button" title="Start buying" class="bg-primary rounded-full relative ml-auto h-12 w-16 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 active:duration-75 active:before:scale-95 dark:before:bg-primaryLight sm:w-auto sm:px-6">
												<span class="relative hidden w-max font-semibold text-white dark:text-gray-900 md:block"> ¡Empieza! </span>
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="relative mx-auto h-6 w-6 text-white dark:text-gray-900 md:hidden">
													<path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
												</svg>
											</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="md:w-[42%] lg:w-1/2">
                            <img src="https://kpjyipdfykgbsoemuxib.supabase.co/storage/v1/object/public/misc/Screenshot%202023-12-26%20at%2015%20(1).00" alt="tailus stat cards components" loading="lazy" width="1299" height="678" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

		<!-- <section class="relative before:absolute before:inset-0 before:h-px before:w-96 before:bg-gradient-to-r before:from-yellow-300 before:via-pink-400 before:to-transparent after:absolute after:inset-0 after:ml-auto after:mt-auto after:h-px after:w-96 after:bg-gradient-to-l after:from-yellow-300 after:via-pink-400 after:to-transparent">
			<div class="border-y border-gray-200 bg-gray-100 dark:border-gray-700 dark:bg-darker">
				<div class="relative mx-auto px-6 md:max-w-full md:px-12 lg:max-w-6xl xl:px-0">
					<div class="items-top justify-between md:flex py-16">
						<div class="h-max md:w-6/12 xl:w-5/12">
							<div class="text-center md:text-left">
								<h2 class="text-3xl font-bold text-gray-800 dark:text-white md:w-max md:text-4xl xl:text-5xl">
									El primero paso <br />
									para crecer
								</h2>
								<p class="mb-8 mt-6 text-gray-600 dark:text-gray-300">¿Listo para revolucionar tu experiencia logística? Danos tu correo y alguien de nuestro equipo estará en contacto para resovler todas tus dudas.</p>
								<div action="" class="mt-12">
									<div class="relative flex items-center rounded-full border border-primary/20 bg-white p-1 px-2 shadow-md focus-within:ring-2 dark:border-white/10 dark:bg-dark dark:text-white md:p-2 lg:pr-3">
										<div class="py-3 pl-4 lg:pl-5">
											<svg class="m-auto h-6 w-6 fill-gray-500 dark:fill-gray-400"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
												<path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
											</svg>
										</div>
										<input v-model="email" autocomplete="email" aria-label="your email" placeholder="Tu correo electrónico" class="w-full rounded-full bg-transparent p-4 placeholder-gray-600 outline-none dark:placeholder-white" type="email" />
										<div class="md:pr-1.5 lg:pr-0">
											<button @click="sendMail(email)" type="button" title="Start buying" class="bg-primary rounded-full relative ml-auto h-12 w-16 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 active:duration-75 active:before:scale-95 dark:before:bg-primaryLight sm:w-auto sm:px-6">
												<span class="relative hidden w-max font-semibold text-white dark:text-gray-900 md:block"> ¡Empieza! </span>
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="relative mx-auto h-6 w-6 text-white dark:text-gray-900 md:hidden">
													<path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
												</svg>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="md:w-[42%] lg:w-1/2">
							<img src="https://kpjyipdfykgbsoemuxib.supabase.co/storage/v1/object/public/misc/Screenshot%202023-12-26%20at%2015.00.17.png" alt="tailus stat cards components" loading="lazy" width="1299" height="678" />
						</div>
					</div>
				</div>
			</div>
		</section> -->
<!-- 
		<section id="testimonials" class="pt-32">
			<div class="mx-auto px-4 sm:px-12 xl:max-w-6xl xl:px-0">
				<div class="text-center">
					<h2 class="text-3xl font-bold text-gray-800 dark:text-white md:text-4xl xl:text-5xl">Trusted by leaders</h2>
					<p class="mx-auto mt-6 text-gray-700 dark:text-gray-300 md:w-3/4 lg:w-3/5">A growing team doesn't need to mean growing pains. Privacy, and the right tool for every step of your journey - Jira Software friction - company size.</p>
				</div>
				<div class="mt-12 grid gap-8 md:grid-cols-2">
					<div class="rounded-3xl border border-gray-100 bg-white p-8 shadow-2xl shadow-gray-600/10 dark:border-gray-700 dark:bg-gray-800 dark:shadow-none sm:col-span-2 sm:px-12 lg:col-span-1 lg:row-span-2">
						<div class="flex h-full flex-col justify-center space-y-6 md:space-y-8">
							<img class="mr-auto h-12 w-auto" src="" loading="lazy" alt="microsoft" />
							<p class="text-gray-600 dark:text-gray-300 md:text-lg lg:text-xl">
								<span class="font-serif">"</span> Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat repellat perspiciatis excepturi est. Non ipsum iusto aliquam consequatur repellat provident, omnis ut, sapiente voluptates veritatis cum deleniti repudiandae ad doloribus. <br />
								<br />
								Esse, sint sit aut ducimus ea ipsam velit saepe earum dolorem, dignissimos minima voluptate quo accusamus corporis, quaerat beatae aliquid. Impedit, accusamus. <span class="font-serif">"</span>
							</p>
							<div class="flex items-center gap-3">
								<img class="h-12 w-12 rounded-full" src="" loading="lazy" alt="user avatar" width="200" height="200" />
								<div>
									<h3 class="text-lg font-semibold leading-none text-gray-600 dark:text-gray-200">Andy Doe</h3>
									<span class="text-sm text-gray-500 dark:text-gray-400">Product Designer</span>
								</div>
							</div>
						</div>
					</div>

					<div class="space-y-6 rounded-3xl border border-gray-100 bg-white p-8 shadow-2xl shadow-gray-600/10 dark:border-gray-700 dark:bg-gray-800 dark:shadow-none">
						<img class="h-12 w-auto" src="" loading="lazy" alt="airbnb" width="" height="" />
						<p class="text-gray-600 dark:text-gray-300"><span class="font-serif">"</span>Sit amet consectetur adipisicing elit. Quaerat repellat perspiciatis excepturi est. Provident, omnis ut, sapiente veritatis cum deleniti repudiandae ad doloribus. <span class="font-serif">"</span></p>
						<div class="flex items-center gap-3 text-left">
							<img class="h-12 w-12 rounded-full" src="" alt="user avatar" width="200" height="200" loading="lazy" />
							<div>
								<h3 class="text-lg font-semibold leading-none text-gray-600 dark:text-gray-200">Janet Doe</h3>
								<span class="text-sm text-gray-500 dark:text-gray-400">UX Designer</span>
							</div>
						</div>
					</div>
					<div class="space-y-6 rounded-3xl border border-gray-100 bg-white p-8 shadow-2xl shadow-gray-600/10 dark:border-gray-700 dark:bg-gray-800 dark:shadow-none">
						<img class="h-12 w-auto" src="" loading="lazy" alt="ge" />
						<p class="text-gray-600 dark:text-gray-300"><span class="font-serif">"</span>Sit amet consectetur adipisicing elit. Quaerat repellat perspiciatis excepturi est. Provident, omnis ut, sapiente veritatis cum deleniti repudiandae ad doloribus. <span class="font-serif">"</span></p>
						<div class="flex items-center gap-3 text-left">
							<img class="h-12 w-12 rounded-full" src="" alt="user avatar" width="200" height="200" loading="lazy" />
							<div>
								<h3 class="text-lg font-semibold leading-none text-gray-600 dark:text-gray-200">John Doe</h3>
								<span class="text-sm text-gray-500 dark:text-gray-400">Product Designer</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section> -->

		<section id="faqs" class="my-32">
			<div class="mx-auto px-4 sm:px-12 xl:max-w-5xl xl:px-0">
				<h2 class="mb-12 text-center text-3xl font-bold text-gray-800 dark:text-white md:text-4xl xl:text-5xl">Preguntas frecuentes</h2>

				<div class="divide-y divide-gray-200 border-y border-gray-200 dark:divide-gray-800 dark:border-gray-800">
					<div v-for="faq in faqs" v-bind:key="faq.title">
						<dl class="faq mx-auto max-w-2xl"  @click="toggle(faq)">
							<dt class="text-lg">
								<button type="button" class="flex w-full items-start justify-between py-6 text-left text-gray-400" aria-controls="faq-0" data-active="false">
									<span class="font-medium text-gray-900 dark:text-white">{{faq.title}}</span>
									<span class="ml-6 flex h-7 items-center">
										<svg v-if="faq.open" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
											<path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
										</svg>

										<svg v-if="!faq.open" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
											<path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
										</svg>
									</span>
								</button>
							</dt>							
							<dd v-if="faq.open" class="faq-answer block pr-12 duration-300 ease-in-out">
								<p class="pb-6 text-base text-gray-600 dark:text-gray-400">{{faq.text}}</p>
							</dd>
						</dl>
					</div>                   
				</div>
			</div>
		</section>
	</div>
</template>
<script>
	import { ref } from 'vue'
	import axios from "axios"
	import { useToast } from 'vue-toast-notification';

	export default {
		name: 'Home',
		setup() {
			const email = ref(null)
			const faqs = ref([{
				title: "¿Cuánto cuesta el servicio?",
				text: "La tarifa mensual se calcula en función de la cantidad de productos que tienes almacenados con nosotros y de las entregas que realizamos a tus clientes. El almacenaje se factura por metro cúbico, y las entregas se facturan según la distancia recorrida en kilómetros.",
				open: false
			},{
				title: "¿Cómo funciona el almacenamiento?",
				text: "Para poder ofrecer entregas rápidas, almacenamos tus productos en nuestra bodega. En cualquier momento, tienes acceso a inventarios en tiempo real de todos tus productos.",
				open: false
			},{
				title: "¿Qué tipos de entregas ofrecen?",
				text: "Ofrecemos entregas el mismo día o al día siguiente. Para los pedidos que ingresen antes de las 10 am, el envío el mismo día es gratis. Para los pedidos que ingresen después de las 10 am, el envío es al día siguiente. Por una tarifa adicional, podemos entregar cualquier pedido el mismo día. Nuestro horario de entregas es de lunes a viernes.",
				open: false
			},{
				title: "¿Cuánto cuesta el portal de gestión de pedidos?",
				text: "¡El uso del portal está incluido de forma gratuita en el servicio!",
				open: false
			}])

			async function sendMail(email) {
				if (!email) {
					return useToast().warning('Favor de llenar tu correo electrónico.', { position: "top-right" });
				}
				await axios.get(`https://hooks.zapier.com/hooks/catch/8448348/3a0e84k/?email=${email}`)
				useToast().success('¡Gracias! Pronto estaremos en contacto y no tendrías que preocuparte por los pedidos y las entregas', { position: "top-right" });
			}

			function toggle(faq) {
				faq.open = !faq.open
			}

			return {
				faqs,
				toggle,
				sendMail,
				email
			}
			
		}
	}
</script>