<template>
	<div class="bg-background-light h-full flex flex-col">

		<div v-if="selectedProducto" class="fixed top-0 left-0 w-full h-full z-50" style="background: rgba(0, 0, 0, 0.5);">
			<div class="w-full h-full">
				<div class=" flex items-center justify-center h-full w-full" :class="{'bg-placeholder' : !selectedProducto.foto}">						
					<div v-if="selectedProducto.foto" class="relative bg-cover bg-no-repeat bg-white bg-center rounded-lg overflow-hidden w-screen mx-16 max-w-2xl " style=" aspect-ratio: 1 / 1 !important;" v-bind:style="{ backgroundImage: 'url(' + selectedProducto.foto + ')' }">	
						<div class="absolute top-4 right-4 w-6 h-6 cursor-pointer" @click="selectedProducto = null">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
								<path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
							</svg>
						</div>										
					</div>
				</div>

			</div>
		</div>

		<div class="border-b h-16 flex flex-row justify-between items-center px-4 bg-white">
			<div>
				<div v-if="data.logo" class="flex items-center h-12 w-12">
					<div class="bg-contain bg-no-repeat bg-center rounded-lg overflow-hidden h-full w-full" v-bind:style="{ backgroundImage: 'url(' + data.logo + ')' }">		
					</div>
				</div>
				<div v-else>
					{{data.nombre}}
				</div>
			</div>		
		</div>
		<div class="overflow-scroll h-full">
			<div class="bg-white m-4 p-4 border border-1 rounded-lg" v-for="producto in productos" v-bind:key="producto.id" >
				<div class="flex flex-row">
					<div class="">
						<div class="flex items-center h-16 w-16 rounded-lg" :class="{'bg-placeholder' : !producto.foto}">
							<div  @click="selectedProducto = producto" v-if="producto.foto" class="cursor-pointer bg-contain bg-no-repeat bg-center rounded-lg overflow-hidden h-full w-full" v-bind:style="{ backgroundImage: 'url(' + producto.foto + ')' }">											
							</div>
						</div>
					</div>

					<div class="pl-4 flex flex-col justify-between w-full">
						<div>      		
							<div class="flex flex-row justify-between items-top w-full">
								<div class="text-lg font-bold">{{producto.nombre}}</div>
								<div v-if="producto.texto" class="py-0.5 pl-2 cursor-pointer" @click="toggleProducto(producto)">								
									<svg v-if="producto.open" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
										<path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
									</svg>

									<svg v-if="!producto.open" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
										<path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
									</svg>
								</div>
							</div>												
						</div>							
						<div class="font-bold text-lg">
							${{producto.todoChido ? producto.precioTodoChido : producto.precio}}
						</div>
					</div>
				</div>

				<div class="mt-2" :class=" producto.open ? 'block' : 'hidden'">{{producto.texto}}</div>				
			</div>
		</div>
	</div>
</template>

<script>	
	import { onMounted, ref, computed } from "vue"
	import { utils } from './utils/utils.js'
	import { useRoute } from 'vue-router'

	export default {
		name: 'Order',
		setup() {			
			const data = ref({productos:[]})
			const route = useRoute()	
			const selectedProducto = ref(null)

			const productos = computed( () => {
				return data.value.productos.filter( p => p.activo ).sort((a,b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0))
			}) 	

			function toggleProducto(producto) {
				if (producto.open) {
					producto.open = !producto.open
				} else {
					producto.open = true
				}		
			}


			onMounted( async () => {
				utils.getCatalogo(route.params.proveedor_id)
				.then( d => {						
					data.value = d
					window.document.title = `${data.value.nombre} - Catalogo de productos`
				})
				.catch( e => {
					console.log(e)
				})
			})	

			return {      				
				data,
				productos,
				toggleProducto,
				selectedProducto
			}
		},
	}
</script> 