<template>
	<div class="h-full overflow-y-scroll">
		<div class="flex flex-row justify-between  items-center pt-4"> 
			<h3 class="text-2xl font-bold  px-4">      		
				Inbox
			</h3>

			<div class="text-left px-4">				
				<div class="border border-primary rounded-full overflow-hidden flex items-center flex flex-row flex-grow text-center cursor-pointer bg-white">
					<div @click="todoChido = false;refreshPedidos()" :class="[{'bg-primary hover:bg-primary-dark text-white': todoChido === false, 'text-primary': todoChido === true}]" class="whitespace-nowrap font-bold py-0.5 px-4">
						PickPack
					</div>
					<div @click="todoChido = true;refreshPedidos()" :class="[{'bg-primary hover:bg-primary-dark text-white': todoChido === true, 'text-primary': todoChido === false}]" class=" whitespace-nowrap font-bold py-0.5 px-4">
						<p>TodoChido</p>
					</div>	
				</div>										
			</div>

		</div>
		<div v-if="pedidos && pedidos.length > 0" class="pb-4"> 
			<inbox class="mt-4" :user=" 'admin'" :ordenes="pedidos" :fields="{cliente: true, evidencia: true, surtir: true, aprobar: false, facturar: false, entregar: true}"></inbox>
		</div>
	</div>
</template>

<script>
	import { supabase } from "../supabase"
	import { onMounted, ref } from "vue"
	// import { useToast } from 'vue-toast-notification';	
	import { useLoading } from 'vue-loading-overlay'

	const loading = useLoading({
		color: "#428b74",
		loader: "spinner"
	});

	export default {
		name: 'Admin Home',
		setup() {			
			const pedidos = ref([])		
			const todoChido = ref(false)

			async function getIds() {
				let { data, error } = await supabase.rpc('pedidos_pendientes')

				if (data) {              
					return data
				} else if (error) { 
					alert(error)     
				}   
			}

			async function getPedidos() {
				if (todoChido.value == false) {
					let ids = await getIds()

					let { data, error } = await supabase
					.from("pedidos")
					.select('*, entregado, proveedor(id, nombre), cliente (*), pedido_factura(*), line_items!inner(id, pedido, cantidad, cantidad_entregada, precio, producto(id, nombre, precio, proveedor(id, nombre))), envios(*)')
					.order('created_at', { ascending: false })													
					.in('id', ids)
					.neq('cliente', 112)				
					.neq('standalone', true)	

					if (data) {              
						return data
					} else if (error) { 
						alert(error)     
					}   
				} else {				
					let { data, error } = await supabase
					.from("pedidos")
					.select('*, entregado, envios(*), proveedor(id, nombre), cliente(*), pedido_factura(*), line_items!inner(id, pedido, cantidad, cantidad_entregada, precio, producto(id, nombre, precio, proveedor(id, nombre)))')
					.order('created_at', { ascending: false })													
					.neq('cancelado', true)					
					.neq('standalone', true)				
					.eq('surtido', false)	
					.or('cliente.eq.16, cliente.eq.112')	

					if (data) {              
						return data
					} else if (error) { 	
						console.log(error)
						alert(error)     
					}
				}
			}

			function refreshPedidos() {
				let loader = loading.show()
				getPedidos()
				.then( data => {
					loader.hide()
					pedidos.value = data.filter( pedido => { 
						if (pedido.require_aprobacion && !pedido.aprobado) {
							return false
						}

						return true
					})
				})		
			}

			onMounted( async () => {				
				getPedidos()
				.then( data => {
					pedidos.value = data.filter( pedido => { 
						if (pedido.require_aprobacion && !pedido.aprobado) {
							return false
						}

						return true
					})
				})			
			})

			return {      
				pedidos,
				todoChido,
				refreshPedidos
			}
		}
	}
</script> 