<template>
	<div class="flex flex-col h-full">
		<div class="flex-auto overflow-scroll">
			<h3 class="text-2xl font-bold pt-4 px-4">      		
				Agregar proveedor
			</h3>

			<div class="w-full p-4">
				<div class="w-full">
					<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
						Nombre
					</label>
					<input class="appearance-none border boder-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:border-gray-500 focus:outline-none w-full" id="nombre" type="text" placeholder="Nombre" v-model="proveedor.nombre">
				</div>

				
			</div>			
		</div>
		<div class="p-4 flex-none border-t border-t-gray-300">
			<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4" @click="crearProveedor">
				Crear proveedor
			</button>
		</div>
	</div>
</template>

<script>	
	import { useRouter } from 'vue-router'
	import { ref } from "vue"
	import { useToast } from 'vue-toast-notification';
	import { useLoading } from 'vue-loading-overlay'
	import { utils } from './utils/utils.js'

	const loading = useLoading({
		color: "#428b74",
		loader: "spinner"
	});

	export default {
		name: 'Order',
		setup() {
			const router = useRouter()			
			const proveedor = ref({})			
			
			async function crearProveedor() {
				if (!proveedor.value.nombre) {
					return alert('Falta el nombre')
				}

				let loader = loading.show()
				utils.crearProveedor(proveedor.value.nombre)	
				.then( () => {
					loader.hide()
					useToast().success('Proveedor creado', { position: "top-right" });
					return router.push(`/admin/proveedores`)
				}) 
				.catch( (error) => {
					loader.hide()
					alert(error)	
				})								
			}

			return {      				
				proveedor,				
				crearProveedor
			}
		},
	}
</script> 
