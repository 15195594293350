<template>
	<div class="flex flex-col h-full">
		<div class="flex-auto overflow-scroll">
			<div class="block lg:flex ldg:flex-row items-center justify-between pt-4"> 
				<h3 class="text-2xl font-bold px-4">      		
					Clientes
				</h3>

				<div class="flex-none lg:flex content-center">
					<!-- <div class="w-full lg:w-auto text-left flex-none px-4 mt-4 lg:mt-0">
						<button class="w-full rounded bg-primary hover:bg-primary-dark text-white font-bold p-1 px-4"  @click="copyLinkAlta()">
							Copiar enlace de alta
						</button>
					</div> -->

					<div class="grow lg:flex-none text-left px-4 mt-4 lg:mt-0 w-full lg:w-auto">				
						<div class="border border-primary rounded-full overflow-hidden flex items-center flex flex-row flex-grow text-center cursor-pointer bg-white w-full lg:w-auto">
							<div @click="activo = true" :class="[{'bg-primary hover:bg-primary-dark text-white': activo === true, 'text-primary': activo === false}]" class="whitespace-nowrap font-bold py-0.5 px-4  w-1/2 lg:w-auto">
								Activo
							</div>
							<div @click="activo = false" :class="[{'bg-primary hover:bg-primary-dark text-white': activo === false, 'text-primary': activo === true}]" class=" whitespace-nowrap font-bold py-0.5 px-4  w-1/2 lg:w-auto">
								Desactivado
							</div>	
						</div>										
					</div>
				</div>
			</div>

			<empty v-if="clientesProveedores && clientesProveedores.length == 0" :texto="'Agregar tu primer cliente usando el botón abajo'"> </empty>

			<div v-if="clientesProveedores && clientesProveedores.length > 10" class="w-full px-4 mt-4">
				<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
					Filtrar clientes
				</label>
				<input class="appearance-none border order-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500  w-full mb-2" id="nombre" type="search" placeholder="Nombre" v-model="filter">						
			</div>

			<div v-if="filteredClientes && filteredClientes.length > 0" class="sm:hidden w-full text-left px-4 pb-4">	
				<div class="border boder-gray-300 rounded p-2 mt-4 bg-white" v-for="clienteProveedor in filteredClientes" v-bind:key="clienteProveedor.id">
					<div class="flex flex-row justify-between items-center">
						<router-link class="h-full flex-grow truncate" :to=' "/proveedor/" + proveedor.id + "/cliente/" + clienteProveedor.cliente.id '>		
							{{clienteProveedor.cliente.nombre}}							
						</router-link>
						<button class="rounded bg-primary hover:bg-primary-dark text-white font-bold py-1 px-4 "  @click="copyLink(clienteProveedor.cliente)">
							Copiar
						</button>
					</div>
				</div>
			</div>

			<div v-if="filteredClientes && filteredClientes.length > 0" class="hidden sm:block w-full text-left p-4">		
				<div class="border border-b-0 boder-gray-300 rounded bg-white">
					<div class="overflow-scroll">
						<table class="w-full text-sm text-left ">
							<thead class="text-s border-b border-b-gray-200">						
								<th scope="col" class="py-3 px-6">
									Cliente
								</th>	
								<th scope="col" class="py-3 px-6" v-if="!proveedor.standalone">
									Aprobación
								</th>
								<th scope="col" class="py-3 px-6">
									Enlace
								</th>										
							</thead>
							<tbody >
								<tr v-for="clienteProveedor in filteredClientes" v-bind:key="clienteProveedor.id" class="border-b">							
									<td scope="row" class="py-3 px-6 font-medium text-gray-900 cursor-pointer ">
										<div class="truncate">
											<router-link class="underline h-full" :to=' "/proveedor/" + proveedor.id + "/cliente/" + clienteProveedor.cliente.id '>
												{{clienteProveedor.cliente.nombre}}
											</router-link>
										</div>
									</td>		
									<td class="py-3 px-6" v-if="!proveedor.standalone">
										{{clienteProveedor.require_aprobacion ? "Si" : "No"}}
									</td>						     
									<td class="py-3 px-6">
										<button class="w-full rounded mr-4 bg-primary hover:bg-primary-dark text-white font-bold p-2 px-4"  @click="copyLink(clienteProveedor.cliente)">
											Copiar
										</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>		
				</div>
			</div>
		</div>
		<div class="p-4 flex-none border-t border-t-gray-300">
			<router-link :to=' "/proveedor/" + proveedor.id + "/cliente" '>
				<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4">
					Agregar cliente
				</button>
			</router-link>
		</div>
	</div>	
</template>

<script>
	import { supabase } from "../supabase"
	import { useRoute } from 'vue-router'
	import { onMounted, ref, computed } from "vue"
	import { useToast } from 'vue-toast-notification';

	export default {
		name: 'Order',
		setup() {
			const route = useRoute()			
			const proveedor = ref({})  
			const clientesProveedores = ref(null)		
			const filter = ref(null)
			const activo = ref(true)

			async function getProveedor() {
				let { data, error, status } = await supabase
				.from("proveedores")
				.select('id, nombre, funciones, standalone')          
				.eq("id", route.params.proveedor_id)
				.single()

				if (error && status !== 406) {
					throw error
				}

				if (data) {   
					proveedor.value = data
					return data
				} else if (error) {  
					alert(error)     
				}   

				return
			}

			async function updateCliente(cliente) {				
				const { error } = await supabase
				.from('cliente_proveedor')
				.update({ 					
					activo: cliente.activo,					
				})
				.match({ id: cliente.id })

				if (error) {					
					alert(error)
				} else {
					useToast().success('Cliente actualizado', { position: "top-right", duration: 5000 });					
				}
			}   

			async function getClientes() {
				let { data, error } = await supabase
				.from("cliente_proveedor")
				.select('id, activo, require_aprobacion, cliente (id, nombre))')  				
				.eq('proveedor', route.params.proveedor_id)

				if (data) {       				
					clientesProveedores.value = data.sort((a, b) => {
						let fa = a.cliente.nombre.toLowerCase(),
						fb = b.cliente.nombre.toLowerCase();

						if (fa < fb) {
							return -1;
						}
						if (fa > fb) {
							return 1;
						}
						return 0;
					});
					console.log(clientesProveedores)
				} else if (error) { 
					alert(error)     
				}   
			} 			

			const showProductosPorCliente = computed( () => {
				return proveedor.value != null && proveedor.value.funciones &&  proveedor.value.funciones.includes('productosPorCliente')
			})

			function copyLink(cliente) {			
				navigator.clipboard.writeText(`${window.location.host}/cliente/${cliente.id}`);
				useToast().success('Enlace copiado', { position: "top-right" });
			}

			const filteredClientes = computed( () => {
				if (!clientesProveedores.value || clientesProveedores.value.length == 0) {
					return []
				}

				return clientesProveedores.value.filter(cP => {
					if (!filter.value) {
						return true
					}					

					return cP.cliente.nombre.toLowerCase().includes(filter.value.toLowerCase())
				}).filter(cP => cP.activo == activo.value)


			})

			// const filteredClientes = computed( () => {
			// 	return clientes.value ? clientes.value.filter(p => p.activo == activo.value).sort((a,b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0)) : []
			// })

			onMounted( async () => {
				getProveedor()
				getClientes()
			})

			function copyLinkAlta() {
				navigator.clipboard.writeText(`${window.location.host}/proveedor/${proveedor.value.id}/alta`);
				useToast().success('Enlace copiado', { position: "top-right" });
			}

			return {      				
				proveedor,
				clientesProveedores,
				copyLink,
				copyLinkAlta,
				updateCliente,
				showProductosPorCliente,
				filteredClientes,
				filter,
				activo
			}
		},
	}
</script> 