<template>
	<div class="bg-background-light h-full flex flex-col select-none">

		<div v-if="selectedProducto" class="fixed top-0 left-0 w-full h-full z-50" style="background: rgba(0, 0, 0, 0.5);">
			<div class="w-full h-full">
				<div class=" flex items-center justify-center h-full w-full" :class="{'bg-placeholder' : !selectedProducto.foto}">						
					<div v-if="selectedProducto.foto" class="relative bg-cover bg-no-repeat bg-center rounded-lg overflow-hidden w-screen mx-16 max-w-2xl " style=" aspect-ratio: 1 / 1 !important;" v-bind:style="{ backgroundImage: 'url(' + selectedProducto.foto + ')' }">	
						<div class="absolute top-4 right-4 w-6 h-6 cursor-pointer" @click="selectedProducto = null">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
								<path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
							</svg>
						</div>										
					</div>
				</div>

			</div>
		</div>

		<div :class=" { 'hidden' : showCheckout }" class="border-b h-16 flex flex-row justify-between items-center px-4 bg-white">
			<div>
				<div v-if="proveedor.logo" class="flex items-center h-12 w-12 my-2">
					<div class="bg-contain bg-no-repeat bg-center rounded-lg overflow-hidden h-full w-full" v-bind:style="{ backgroundImage: 'url(' + proveedor.logo + ')' }">		
					</div>
				</div>
				<div v-else>
					{{proveedor.nombre}}
				</div>
			</div>
			<div>				
				${{Object.keys(cart).length > 0 ? Object.keys(cart).map( key => cart[key].precio * cart[key].cantidad).reduce((a, b) => a + b) : 0}}
			</div>
		</div>


		
		<div :class=" { 'hidden' : showCheckout } " class="overflow-scroll h-full">

			<div class="mt-4 mx-4">
				<div class="block lg:flex lg:flex-row lg:gap-4">
					<div v-if="proveedor.foto" class="bg-cover bg-no-repeat bg-center rounded-lg overflow-hidden w-full lg:w-1/3 h-72" style=" aspect-ratio: 1 / 1 !important;" v-bind:style="{ backgroundImage: '	url(' + proveedor.foto + ')' }">				
					</div>
					<div class="w-full lg:w-2/3">
						<div class="text-2xl font-bold mt-4 lg:mt-0">{{proveedor.nombre}}</div>
						<div class="pt-2">{{proveedor.texto}}</div>
					</div>
				</div>
			</div>


			<div v-if="proveedor.id && productos.length == 0" class="">
				<div class="border boder-gray-300 rounded m-4">
					<p class="p-8 text-center">
						Actualmente no hay productos disponibles.
					</p>
				</div>
			</div>

			<div class="bg-white m-4 p-4 border border-1 rounded-lg" v-for="producto in productos" v-bind:key="producto.id" >
				<div class="flex flex-row">
					<div class="">
						<div class="flex items-center h-12 w-12 rounded-lg" :class="{'bg-placeholder' : !producto.foto}">
							<div @click="selectedProducto = producto" v-if="producto.foto" class="cursor-pointer bg-contain bg-no-repeat bg-center rounded-lg overflow-hidden h-full w-full" v-bind:style="{ backgroundImage: 'url(' + producto.foto + ')' }">											
							</div>
						</div>
					</div>

					<div class="pl-4 flex flex-col justify-between w-full">
						<div>      		
							<div class="flex flex-row justify-between items-top w-full">
								<div class="text-lg font-bold">{{producto.nombre}}</div>
								<div v-if="producto.texto" class="py-0.5 pl-2 cursor-pointer" @click="toggleProducto(producto)">								
									<svg v-if="producto.open" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
										<path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
									</svg>

									<svg v-if="!producto.open" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
										<path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
									</svg>
								</div>
							</div>												
						</div>	

						<div class="font-bold">
							${{producto.precio_publico || producto.precio}}
						</div>
					</div>
				</div>

				<div class="mt-2" :class=" producto.open ? 'block' : 'hidden'">{{producto.texto}}</div>

				<div class="flex px-4 justify-between w-full rounded-full border border-1 p-2 mt-4">
					<div class="cursor-pointer" @click="subtract(producto)" :class=" !cart[producto.id] ? 'opacity-30' : 'opacity-100' ">
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
							<path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
						</svg>
					</div>
					<div>
						{{cart[producto.id] && cart[producto.id].cantidad || 0}}
					</div>
					<div class="cursor-pointer" @click="add(producto)" :class=" cart[producto.id] && cart[producto.id].cantidad == producto.cantidad ? 'opacity-30' : 'opacity-100' ">
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
							<path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
						</svg>

					</div>
				</div>
			</div>
		</div>

		<div :class=" { 'hidden' : !showCheckout } " class="overflow-scroll h-full p-4 ">

			<div class="pb-0">
				<div class="flex flex-row justify-between items-center">
					<div class="text-xl font-bold">
						Checkout
					</div>

					<div class="cursor-pointer" @click="toggleCheckout()">
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
							<path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
						</svg>
					</div>
				</div>

				<div class="flex flex-row justify-between items-center pt-8">
					<div class="text-lg font-bold">
						Carrito
					</div>					
					<div v-if="Object.keys(cart).length > 0" class="cursor-pointer" @click="toggleCart()">			
						<svg v-if="cartOpen" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
							<path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
						</svg>

						<svg v-if="!cartOpen" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
							<path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
						</svg>
					</div>					
				</div>
			</div>


			<div v-if="Object.keys(cart).length == 0">			
				<div v-if="showCheckout && Object.keys(cart).length == 0" class="">
					<div class="border boder-gray-300 rounded mt-4 bg-white">
						<p class="p-8 text-center">
							Tu carrito está vacio
						</p>
					</div>
				</div>


			</div>

			<div v-if="Object.keys(cart).length > 0">

				<div v-if="!cartOpen" class="border boder-gray-300 rounded mt-4 bg-white">
					<p class="px-8 py-4 text-center">
						Tienes {{getTotalItems()}} {{getTotalItems() > 1 ? "productos" : "producto"}} en tu carrito.
					</p>
				</div>	

				<div v-if="cartOpen" >
					<div class="bg-white mt-4 p-4 border border-1 rounded-lg" v-for="producto in productos" v-bind:key="producto.id" >
						<div class="flex flex-row">
							<div class="">
								<div class="flex items-center h-12 w-12 rounded-lg " :class="{'bg-placeholder' : !producto.foto}">
									<div v-if="producto.foto" class="cursor-pointer bg-contain bg-no-repeat bg-center rounded-lg overflow-hidden h-full w-full" v-bind:style="{ backgroundImage: 'url(' + producto.foto + ')' }">											
									</div>
								</div>
							</div>

							<div class="pl-4 flex flex-col justify-between">						   		
								<div class="text-lg font-bold">{{producto.nombre}}</div>					
								<div class="font-bold">
									${{producto.precio_publico || producto.precio}}
								</div>
							</div>
						</div>

						<div class="flex px-4 justify-between w-full rounded-full border border-1 p-2 mt-4">
							<div class="cursor-pointer" @click="subtract(producto)" :class=" !cart[producto.id] ? 'opacity-30' : 'opacity-100' ">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
									<path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
								</svg>
							</div>
							<div>
								{{cart[producto.id] && cart[producto.id].cantidad || 0}}
							</div>
							<div class="cursor-pointer" @click="add(producto)" :class=" cart[producto.id] && cart[producto.id].cantidad == producto.cantidad ? 'opacity-30' : 'opacity-100' ">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
									<path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
								</svg>

							</div>
						</div>
					</div>	
				</div>
			</div>		

			<div class="text-lg font-bold pt-8">
				Contacto
			</div>

			<label class="block mt-2">
				<span>
					Nombre y apellido
				</span>
				<input v-model="customer.name" type="text" name="nombre" class="mt-1 px-3 py-2 bg-white border border-1 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 h-11" placeholder="Nombre" autocomplete="name">
			</label>


			<label class="block mt-2">
				<span>
					Correo
				</span>
				<input :class="{ 'border-red': errors.email == true }" v-model="customer.email" type="email" name="email" class="mt-1 px-3 py-2 bg-white border border-1 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 h-11" placeholder="yo@ejemplo.mx" autocomplete="email" v-on:focusout="validateEmail()">
			</label>

			<label class="block mt-2">
				<span>
					Teléfono
				</span>
				<div class="flex">
					<select id="pais" v-model="pais" class="mt-1 px-3 py-2 bg-white border border-1 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block rounded-md sm:text-sm focus:ring-1 w-20 mr-2 h-11" >
						<option selected value=52>MX</option>
						<option selected value=1>USA</option>
					</select>
					<input :class="{ 'border-red': errors.phone == true }" v-model="customer.phone" type="tel" name="cel" class="mt-1 px-3 py-2 bg-white border border-1 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 h-11" placeholder="55 5555 5555" autocomplete="tel" v-on:focusout="validatePhone()">
				</div>
			</label>

			<div class="text-lg font-bold pt-8">
				Entrega	
			</div>

			<p class="text-gray-500">
				Contamos con entrega a CDMX y la zona Metropolitana
			</p>

			<div class="grid grid-cols-2 gap-4">

				<label class="block mt-2">
					<span >
						Calle
					</span>
					<input v-model="address.calle" type="text" name="nombre" class="mt-1 px-3 py-2 bg-white border border-1-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 h-11" placeholder="Calle" autocomplete="street-address" v-on:focusout="getDeliveryCost()">
				</label>

				<label class="block mt-2">
					<span >
						Exterior
					</span>
					<input v-model="address.exterior" type="text" name="" class="mt-1 px-3 py-2 bg-white border border-1-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 h-11" placeholder="Exterior" autocomplete="nope" v-on:focusout="getDeliveryCost()">
				</label>

			</div>

			<div class="grid grid-cols-2 gap-4">				
				<label class="mt-2">
					<span >
						Interior
					</span>
					<input v-model="address.interior" type="text" name="interior" class="mt-1 px-3 py-2 bg-white border border-1 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 h-11" placeholder="Interior">
				</label>

				<label class="mt-2">
					<span>
						Código postal
					</span>
					<input :class="{ 'border-red': errors.postal_code == true }" v-model="address.postal_code" type="text" name="postal-code" class="mt-1 px-3 py-2 bg-white border border-1 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 h-11" placeholder="00000" autocomplete="postal-code" v-on:focusout="validatePostalCode(),getDeliveryCost()">
				</label>

			</div>

			<div>
				<label class="block mt-2">
					<span >
						Colonia
					</span>
					<input v-model="address.city" type="text" name="Ciudad" class="mt-1 px-3 py-2 bg-white border border-1 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 h-11" placeholder="Colonia" autocomplete="address-level3" v-on:focusout="getDeliveryCost()">
				</label>
			</div>

			<label class="block mt-2">
				<span >
					Estado
				</span>
				<select v-model="address.estado" class="mt-1 border border-1 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-10">
					<option selected value="Ciudad de México">Ciudad de México</option>		
					<option value="Estado de México">Estado de México</option>							
				</select>
			</label>

			<div class="text-lg font-bold pt-8">
				Envío
			</div>

			<div class="mt-2">
				<div class="border boder-gray-300 rounded bg-white  mb-4" v-if="proveedor.free_delivery_cutoff && getTotal(false) <= proveedor.free_delivery_cutoff">
					<p class="p-8 py-4 text-center">
						Agrega ${{proveedor.free_delivery_cutoff - getTotal(false)}} a tu carrito para tener envío estándar gratis.
					</p>
				</div>

				<div class="border boder-gray-300 rounded mt-4 mb-4" v-if="proveedor.free_delivery_cutoff && getTotal(false) > proveedor.free_delivery_cutoff">
					<p class="p-8 py-4 text-center">
						¡Tienes envío estándar gratis!
					</p>
				</div>

				<label>					
					<select v-model="option" class="border border-1 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-10">
						<option v-for="(option, index) in options" v-bind:key="index" :value="option">{{option.nombre}} {{option.costo ? `- $${option.costo}` : ""}}</option>					
					</select>
				</label>				
			</div>


			

			<div class="text-lg font-bold pt-8">
				Pago
			</div>
			<div class="group mt-2 mb-8">
				<label>
					<span>Número de tarjeta</span>						
					<div id="card-number-element" class="mt-1 px-3 py-0.5 bg-white border border-1 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 h-11"></div>
				</label>

				<div class="grid grid-cols-2 gap-4 mt-2">
					<div>
						<label>
							<span>Fecha de vencimiento</span>
							<div id="card-expiry-element" class="mt-1 px-3 py-0.5 bg-white border border-1 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 h-11"></div>
						</label>
					</div>
					<div class="">
						<label class="flex justify-between h-full flex-col">
							<span>CVC</span>
							<div id="card-cvc-element"  class="mt-1 px-3 py-0.5 bg-white border border-1 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 h-11"></div>
						</label>
					</div>
				</div>									
			</div>
		</div>		

		<div :class=" { 'hidden' : !showCheckout } " class="w-full border border-t bg-white px-4 py-4">
			<div id="card-errors" role="alert" class=" text-center text-red"></div>
			
			<div class="w-full rounded-full flex flex-row items-center justify-between">
				<div class="text font-bold">Total:</div>
				<div class="text font-bold">${{getTotal(true)}}</div>
			</div>

			<div @click="charge()" class="h-10 w-full cursor-pointer mt-4">
				<div :style="`background-color:${proveedor.color || '#428b74'}`" class="w-full rounded-full text-white h-full flex items-center">
					<div class=" text-center w-full">Pagar</div>
				</div>
			</div>
		</div>

		<div v-if="proveedor.id && productos.length > 0" :class=" { 'hidden' : showCheckout } "  @click="toggleCheckout()" class="z-99 w-full cursor-pointer py-4 px-4 border border-t bg-white">
			<div :style="`background-color:${proveedor.color || '#428b74'}`" class="h-10 w-full rounded-full text-white flex items-center">
				<div class=" text-center w-full">Proceder al pago</div>
			</div>
		</div>
	</div>
</template>

<script>	
	import { useRoute, useRouter } from 'vue-router'
	import { onMounted, ref, computed, toRaw } from "vue"
	import { utils } from './utils/utils.js'
	import axios from "axios"
	import { useLoading } from 'vue-loading-overlay'
	import { supabase } from "../supabase"
	import { useToast } from 'vue-toast-notification';

	export default {
		name: 'Shop',
		setup() {			
			const route = useRoute()
			const router = useRouter()
			const proveedor = ref({productos:[]})				
			const cart = ref({})
			const showCheckout = ref(false)
			const address = ref({
				estado: "Ciudad de México"
			})
			const errors = ref({})
			const customer = ref({})
			const pais = ref("52")			
			const stripe = ref({})
			const elements = ref({})
			const cardNumberElement = ref({})
			const options = ref([])
			const option = ref({})
			const cliente = ref(null)
			const deliveryCost = ref(null)
			const cartOpen = ref(false)
			const selectedProducto = ref(null)

			var loading;

			let loader;	

			onMounted( async () => {				
				setOptions()				

				var style = {
					base: {
						iconColor: '#666EE8',
						color: '#31325F',
						lineHeight: '38px',
						fontWeight: 300,
						fontFamily: 'ui-sans-serif',
						fontSize: '16px',
						'::placeholder': {
							color: '#b2b2b9',
						},
					},
				};

				stripe.value = window.Stripe('pk_live_Zi8ozD62RnknTUOwVetkTPrf003KawSnHs', {
					locale: 'es-419'
				});

				elements.value = stripe.value.elements();			

				cardNumberElement.value = elements.value.create('cardNumber', {
					style: style,
					placeholder: 'Número de tarjeta',
					showIcon: true
				})

				cardNumberElement.value.mount('#card-number-element');

				var cardExpiryElement = elements.value.create('cardExpiry', {
					style: style,
					placeholder: 'MM/AA',
				});
				cardExpiryElement.mount('#card-expiry-element');

				var cardCvcElement = elements.value.create('cardCvc', {
					style: style,
					placeholder: 'CVC',
				});
				cardCvcElement.mount('#card-cvc-element');

				cardNumberElement.value.on('change', function(event) {
					showError(event);
				});

				cardExpiryElement.on('change', function(event) {
					showError(event);
				});

				cardCvcElement.on('change', function(event) {
					showError(event);
				});

				utils.getDataEcom(route.params.shop_name)
				.then( d => {		
					console.log('Data', d)							
					proveedor.value = d

					checkCliente()

					try {
						console.log(`cart_pp_shop_${proveedor.value.id}`, localStorage.getItem(`cart_pp_shop_${proveedor.value.id}`))
						if (localStorage.getItem(`cart_pp_shop_${proveedor.value.id}`)) {

							let storedCart = JSON.parse(localStorage.getItem(`cart_pp_shop_${proveedor.value.id}`))
							console.log('stored_cart', storedCart)
							let productIds = proveedor.value.productos.map( p => p.id )
							console.log('productIds', productIds)

							let cartProducts = Object.keys(storedCart).map( p => Number(p))
							console.log('cartProducts', cartProducts)

							cartProducts.forEach( productId => {
								if (!productIds.find( p => p == productId)) {
									console.log('Deleting', productId)
									delete storedCart[productId]
								}
							})						

							console.log('cartProducts', cartProducts)

							cart.value = storedCart

							let cartString = JSON.stringify(toRaw(cart.value))
							localStorage.setItem(`cart_pp_shop_${proveedor.value.id}`, cartString)
						}			
					} catch (e) {
						console.log('Hubo un error con el carrito', e)
					}

					loading = useLoading({
						color: d.color || "#428b74",
						loader: "spinner"
					});
				})
				.catch( e => {
					console.log(e)
				})
			})	

			async function checkCliente() {
				if (route.query.cliente) {	 	
					try {								
						let clienteData = await utils.getCliente(route.query.cliente)

						if (clienteData) {
							cliente.value = clienteData
							address.value.exterior = cliente.value.direccion_exterior
							address.value.interior = cliente.value.direccion_interior
							address.value.calle = cliente.value.direccion_calle
							address.value.city = cliente.value.direccion_colonia
							address.value.postal_code = cliente.value.direccion_codigo
							customer.value.name = cliente.value.contacto_nombre
							customer.value.phone = cliente.value.contacto_telefono
							customer.value.email = cliente.value.contacto_correo

							getDeliveryCost()
						}
					} catch( e) {
						console.log(e)
					}
				}
			}

			function add(producto) {
				if (cart.value[producto.id] && cart.value[producto.id].cantidad && cart.value[producto.id].cantidad < producto.cantidad) {					
					cart.value[producto.id].cantidad += 1
					cart.value[producto.id].precio = producto.precio_publico || producto.precio
				} else if (cart.value[producto.id] && cart.value[producto.id].cantidad && cart.value[producto.id].cantidad) {
					//Nothing
				} else {
					cart.value[producto.id] = {}
					cart.value[producto.id].cantidad = 1
					cart.value[producto.id].precio = producto.precio_publico || producto.precio
				}

				let cartString = JSON.stringify(toRaw(cart.value))
				console.log('add', cartString)
				localStorage.setItem(`cart_pp_shop_${proveedor.value.id}`, cartString)

				if (showCheckout.value == true) {
					setOptions()
				}
			}

			function subtract(producto) {
				if (cart.value[producto.id] && cart.value[producto.id].cantidad > 1) {
					cart.value[producto.id].cantidad += -1
				} else {
					delete cart.value[producto.id]
				}

				let cartString = JSON.stringify(toRaw(cart.value))
				console.log('subtract', cartString)
				localStorage.setItem(`cart_pp_shop_${proveedor.value.id}`, cartString)

				if (showCheckout.value == true) {
					setOptions()
				}
			}

			function toggleCheckout() {
				showCheckout.value = !showCheckout.value					
			}

			const productos = computed( () => {
				if (!proveedor.value.productos || proveedor.value.productos.length == 0) {
					return []
				}

				return proveedor.value.productos.filter( p => showCheckout.value ? cart.value[p.id] && cart.value[p.id].cantidad > 0 : p.cantidad > 0).sort((a,b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0))
			}) 

			function validatePostalCode()  {
				if (!address.value.postal_code) {					
					errors.value.postal_code = false
				}

				if (/\b\d{5}\b/g.test(address.value.postal_code)) {					
					errors.value.postal_code = false		
				} else {					
					errors.value.postal_code = true
					deliveryCost.value = null
					setOptions()
				}				
			}
			
			function validateEmail()  {		
				if (!customer.value.email) {					
					errors.value.email = false
				}

				if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(customer.value.email)) {					
					errors.value.email = false		
				} else {					
					errors.value.email = true
				}		
			}

			function validatePhone() {
				if (!customer.value.phone) {					
					errors.value.phone = false
				}

				if (/^\d(?:[- ]*\d){9,9}$/.test(customer.value.phone)) {					
					errors.value.phone = false		
				} else {					
					errors.value.phone = true
				}				
			}	

			async function getSecret() {
				let data = {					
					total: getTotal(true)
				}
				
				return await axios.post('/api/create_payment', data)
			}

			function showError(event) {
				var displayError = document.getElementById('card-errors');
				if (event.error) {
					displayError.textContent = event.error.message;
				} else {
					displayError.textContent = '';
				}
			}

			function paymentIsValid() {
				let elements = ['card-number-element', 'card-expiry-element', 'card-cvc-element']
				
				let isInvalid = elements.map( element => {				
					const elementToValidate = document.getElementById(element);					
					return elementToValidate.classList.contains('StripeElement--empty')			
				}).filter( e => e == true)

				if (isInvalid.length > 0) {
					return false
				}

				return true
			}

			function getTotalItems() {
				return (Object.keys(cart.value).length > 0 ? Object.keys(cart.value).map( key => cart.value[key].cantidad).reduce((a, b) => a + b) : 0)
			}

			function getTotal(includeDelivery) {
				return (Object.keys(cart.value).length > 0 ? Object.keys(cart.value).map( key => cart.value[key].precio * cart.value[key].cantidad).reduce((a, b) => a + b) : 0) + (includeDelivery ? option.value.costo : 0)
			}

			async function charge() {		
				if (!customer.value.name || !customer.value.email || !customer.value.phone) {
					return alert("¡Faltan campos por llenar!");
				}

				if (!address.value.calle || !address.value.exterior || !address.value.postal_code || !address.value.city) {
					return alert("¡Faltan campos por llenar!");
				}

				if (errors.value.email == true) {
					return alert('¡Verifica que tu correo sea correcto!')
				}

				if (errors.value.phone == true) {
					return alert('¡Verifica que tu número sea correcto!')
				}

				if (errors.value.postal_code == true) {
					return alert('¡Revisa tu código postal!')
				}

				if (!paymentIsValid()) {
					return alert('Favor de completar los datos del pago.')
				}

				if (!deliveryCost.value) {
					return alert("Favor de escoge una opción de envío.");
				}

				loader = loading.show()

				let data = await getSecret()

				stripe.value.confirmCardPayment(data.data.clientSecret, {
					payment_method: {
						card: cardNumberElement.value,
						billing_details: {
							name: customer.value.name,
							address: {
								city: address.value.city,
								country: "MX",
								line1: `${address.value.calle} ${address.value.exterior}`,
								postal_code: address.value.postal_code,
								state: "Ciudad de México"
							}
						},
					}
				}).then( async result => {
					console.log("result", result, result.error)
					if (result.error) {						
						loader.hide()
						alert('No pudimos procesar tu pago. Favor de intentarlo con otra tarjeta.')							
					} else {					
						if (!cliente.value) {
							let c = await crearCliente()	
							console.log('Cliente creado', c)
							cliente.value = c							
						}						

						let clienteProveedor = await getClienteProveedor()

						if (!clienteProveedor) {
							await createClienteProveedor()
						}

						let response = await crearPedido(cliente.value)
						await mandarConfirmacion(response.data.pedido)
						
						localStorage.clear()

						loader.hide()
						useToast().success('¡Gracias por tu pedido!', { position: "top-right" });
						router.push(`/shop/pedido/${response.data.pedido.id}`)
					}					
				})				
			}

			async function getClienteProveedor() {
				let { data, error } = await supabase
				.from('cliente_proveedor') 
				.select('*')
				.eq('cliente', cliente.value.id)
				.eq('proveedor', proveedor.value.id)

				console.log(error, data)
				if (data && data.length > 0) {
					return data[0]
				} else if (data) {
					return 
				} else if (error) {
					throw error
				}
			}

			async function createClienteProveedor() {
				let { error } = await supabase				
				.from('cliente_proveedor')
				.insert([{
					"cliente": cliente.value.id,
					"proveedor": proveedor.value.id,
					"activo": false
				}])				

				console.log(error)

				if (error) {
					throw error 			
				} else {  					
					return 
				}     
			}

			async function crearCliente() {  								
				let clienteParaUpsert = {
					"nombre": customer.value.name,
					"rfc": "",
					"rfc_enlace": "",
					"direccion_exterior": address.value.exterior,
					"direccion_interior": address.value.interior || "",
					"direccion_calle": address.value.calle,
					"direccion_colonia": address.value.city,
					"direccion_codigo": address.value.postal_code,
					"direccion_estado":  address.value.estado,
					"contacto_nombre": customer.value.name,
					"contacto_telefono": customer.value.phone,
					"contacto_correo": customer.value.email,
					"horario": {
						lunes: { open: false },
						martes: { open: false },
						miercoles: { open: false },
						jueves: { open: false },
						viernes: { open: false },
						sabado: { open: false },
						domingo: { open: false }
					}				
				}			

				let { data, error } = await supabase
				.from('clientes')
				.upsert(clienteParaUpsert)
				.select()
				.single()

				if (data) {         									
					return data
				} else if (error) { 			
					throw error
				}     
			}	

			async function crearPedido(cliente) {
				console.log('cliente', cliente)
				let pedido = {
					"ecom": true,
					"cliente": cliente.id,
					"proveedor": proveedor.value.id,
					"instrucciones": "Pedido de la tienda en línea",
					"require_entrega": true,
					"require_factura": false,
					"envio_type": "entrega",
					"datos_entrega": {
						"direccion": {
							"calle": cliente.direccion_calle,
							"interior": (cliente.direccion_interior || ""),
							"exterior": cliente.direccion_exterior,
							"codigo": cliente.direccion_codigo,
							"colonia": cliente.direccion_colonia,
							"estado": cliente.direccion_estado
						},
						"horario": cliente.horario,
						"contacto": {
							"nombre": cliente.contacto_nombre,
							"telefono": cliente.contacto_telefono
						},
						"recipiente": cliente.nombre
					}
				}				

				let line_items = Object.keys(cart.value).map( key => {
					return {						
						"producto": key,
						"cantidad": cart.value[key].cantidad,
						"precio": cart.value[key].precio,
						"proveedor": proveedor.value.id					
					}
				})

				let response = await axios.post('/api/create_orden', {
					pedido: pedido,
					line_items: line_items
				})

				return response
			}

			async function mandarConfirmacion(pedido) {				
				return axios.post('/api/mandar_confirmacion', {
					orden_id: pedido.id
				})					
			}

			function toggleProducto(producto) {
				if (producto.open) {
					producto.open = !producto.open
				} else {
					producto.open = true
				}		
			}

			async function getDeliveryCost() {		
				console.log('getDeliveryCost, checking data', address.value.calle, address.value.exterior, address.value.postal_code, address.value.city, errors.value.postal_code)
				if (!address.value.calle || !address.value.exterior || !address.value.postal_code || !address.value.city || errors.value.postal_code) {
					console.log('missing data')
					deliveryCost.value = null
					setOptions()					
					return
				}

				console.log('calculating delivery cost')

				let delivery_data = {
					"proveedor": proveedor.value.id,
					"direccion_exterior": address.value.exterior,					
					"direccion_calle": address.value.calle,
					"direccion_colonia": address.value.city,
					"direccion_codigo": address.value.postal_code				
				}

				console.log(delivery_data)

				let costo = await axios.post('/api/delivery_cost', delivery_data)

				deliveryCost.value = Math.round(costo.data)

				setOptions()
				console.log(deliveryCost.value)
			}

			function toggleCart() {
				console.log('toggling, cart', cartOpen.value)
				cartOpen.value = !cartOpen.value
			}

			function setOptions() {
				const d = new Date();
				if (deliveryCost.value == null) {
					options.value = [{
						nombre: "Llena los datos de entrega para opciones de envío",
						costo: null
					}]

					option.value = options.value[0]
				} else {
					if (d.getDay() <= 5) {
						options.value = [{
							nombre: "Mañana, transcurso del día",
							costo: proveedor.value.free_delivery_cutoff ? (getTotal(false) < proveedor.value.free_delivery_cutoff ? deliveryCost.value : 0) : deliveryCost.value
						}]
					} else if (d.getDay() > 5) {
						options.value = [{
							nombre: "Lunes, transcurso del día",
							costo: proveedor.value.free_delivery_cutoff ? (getTotal(false) < proveedor.value.free_delivery_cutoff ? deliveryCost.value : 0) : deliveryCost.value
						}]
					}

					if (d.getDay() <= 5 && d.getHours() <= 16) {
						options.value.push({
							nombre: "Mismo día",
							costo: proveedor.value.free_delivery_cutoff ? (getTotal(false) < proveedor.value.free_delivery_cutoff ? deliveryCost.value + proveedor.value.pricing.express_entre_semana : proveedor.value.pricing.express_entre_semana) : deliveryCost.value + proveedor.value.pricing.express_entre_semana
						})
					} else if (d.getDay() > 5 && d.getHours() <= 16) {
						options.value.push({
							nombre: "Mismo día",
							costo: proveedor.value.free_delivery_cutoff ? (getTotal(false) < proveedor.value.free_delivery_cutoff ? deliveryCost.value + proveedor.value.pricing.express_fin_de_semana : proveedor.value.pricing.express_fin_de_semana) : deliveryCost.value + proveedor.value.pricing.express_fin_de_semana
						})
					}

					option.value = options.value[0]
				}
			}

			return {      				
				proveedor,
				add,
				subtract,
				cart,
				toggleCheckout,
				productos,
				address,
				customer,
				errors,
				validatePostalCode,
				validateEmail,
				validatePhone,
				pais,
				charge,
				toggleProducto,
				showCheckout,
				options,
				option,
				getTotal,
				getTotalItems,
				cliente,
				getDeliveryCost,
				cartOpen,
				toggleCart,
				selectedProducto				
			}
		},
	}
</script> 