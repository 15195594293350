<template>
	<div class="flex flex-col h-full">
		<div v-if="proveedor.id == 21">
			<empty :texto="'Para Todo Chido los productos se tienen que editar desde el portal de admin.'"> </empty>
		</div>

		<div v-if="proveedor.id != 21" class="flex-auto overflow-scroll">
			<div class="block lg:flex ldg:flex-row items-center justify-between pt-4"> 
				<h3 class="text-2xl font-bold pl-4">      		
					Productos
				</h3>

				<div class="text-left px-4 mt-4 lg:mt-0 w-full lg:w-auto">				
					<div class="border border-primary rounded-full overflow-hidden flex items-center flex flex-row flex-grow text-center cursor-pointer bg-white w-full lg:w-auto">
						<div @click="archivado = false" :class="[{'bg-primary hover:bg-primary-dark text-white': archivado === false, 'text-primary': archivado === true}]" class="whitespace-nowrap font-bold py-0.5 px-4 w-1/2 lg:w-auto">
							Disponible
						</div>
						<div @click="archivado = true" :class="[{'bg-primary hover:bg-primary-dark text-white': archivado === true, 'text-primary': archivado === false}]" class=" whitespace-nowrap font-bold py-0.5 px-4 w-1/2 lg:w-auto">
							Archivado
						</div>	
					</div>										
				</div>
			</div>

			<empty v-if="productos && productos.length == 0" :texto="'Crea tu primer producto usando el botón abajo'"> </empty>
			<div v-if="productos && productos.length > 0" class="sm:hidden w-full text-left px-4 pb-4">					
				<div class="border boder-gray-300 rounded p-2 mt-4 cursor-pointer bg-white" v-for="producto in filteredProductos" v-bind:key="producto.id">	
					<router-link :to='getLink(producto)'>
						<div class="flex">
							<div v-if="producto.foto" class="rounded overflow-hidden w-20 mr-4">
								<img :src="producto.foto" class="">
							</div>
							<div class="flex flex-col flex-grow">
								<div class="flex items-center ">												
									<div class="flex items-center">														
										{{producto.nombre}}
									</div>
								</div>
								<div class="mt-2 grid flex-grow" :class=" proveedor.standalone ? 'grid-cols-2' : 'grid-cols-3' ">
									<div>
										<label class="block text-gray-700 text-sm font-bold" for="nombre">
											Precio
										</label>
										<div class="flex items-center">
											${{producto.precio}}
										</div>
									</div>
									<div v-if="!proveedor.standalone">
										<label class="block text-gray-700 text-sm font-bold" for="nombre">
											Cantidad
										</label>
										<div class="flex items-center">
											{{producto.cantidad}}
										</div>
									</div>
									<div>
										<label class="block text-gray-700 text-sm font-bold" for="nombre">
											Activo
										</label>
										<div class="flex items-center">
											{{producto.activo ? "Si" : "No"}}
										</div>
									</div>
								</div>
							</div>
						</div>
					</router-link>
				</div>
			</div>
			<div v-if="productos && productos.length > 0" class="hidden sm:block w-full text-left p-4 ">
				<div class="border border-b-0 boder-gray-300 rounded bg-white">
					<div class="overflow-scroll">
						<table class="w-full text-sm text-left ">
							<thead class="text-s border-b border-b-gray-200">						
								<th scope="col" class="py-3 px-6">
									Nombre
								</th>
								<th scope="col" class="py-3 px-6">
									Precio
								</th>	
								<th scope="col" class="py-3 px-6" v-if="!proveedor.standalone">
									Cantidad
								</th>					
								<th scope="col" class="py-3 px-6">
									Activo
								</th>						
							</thead>
							<tbody>
								<tr class="text-s border-b" v-for="producto in filteredProductos" v-bind:key="producto.id">
									<th scope="row" class="py-3 px-6 font-medium text-gray-900 whitespace-nowrap underline">								
										<div class="flex items-center">					
											<div v-if="producto.foto" class="rounded overflow-hidden w-16 mr-4">
												<img :src="producto.foto" class="">
											</div>
											<router-link :to='getLink(producto)'>
												{{producto.nombre}}
											</router-link>
										</div>
									</th>
									<td class="py-3 px-6">							
										${{producto.precio}}
									</td>
									<td class="py-3 px-6" v-if="!proveedor.standalone">							
										{{producto.cantidad}}
									</td>						
									<td class="py-3 px-6">
										{{producto.activo ? "Si" : "No"}}
									</td>
								</tr>              
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div v-if="proveedor.id && proveedor.id != 21" class="p-4 flex-none border-t border-t-gray-300">
			<router-link :to=' "/proveedor/" + proveedor.id + "/producto" '>
				<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4">
					Agregar producto
				</button>
			</router-link>
		</div>

		<div v-if="admin" class="p-4 flex-none border-t border-t-gray-300">
			
			<label class="block text-gray-700 text-sm font-bold mb-2" >
				Producto
			</label>

			<select v-model="producto" v-if="allProductos.length > 0" id="countries" class="mb-4 border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
				<option selected disabled value="">Escoger un producto</option>
				<option v-for="producto in allProductos.sort((a,b) => (a.proveedor.nombre > b.proveedor.nombre) ? 1 : ((b.proveedor.nombre > a.proveedor.nombre) ? -1 : 0))" v-bind:key="producto.id" :value="producto">{{`${producto.proveedor.nombre} - ${producto.nombre}`}}</option>			
			</select>

			<button @click="addProductoToTodoChido(producto)" class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4">
				Agregar producto a Todo Chido
			</button>
			
		</div>
	</div>
</template>

<script>	
	import { useRoute } from 'vue-router'
	import { onMounted, ref, computed } from "vue"
	import { utils } from './utils/utils.js'
	import { useLoading } from 'vue-loading-overlay'
	import { useToast } from 'vue-toast-notification';

	export default {
		name: 'Proveedor - Productos',		
		setup() {
			const route = useRoute()			
			const proveedor = ref({})  
			const productos = ref([])	
			const producto = ref("")	
			const allProductos = ref([])	
			const archivado = ref(false)
			const admin = ref(false)			

			const loading = useLoading({
				color: "#428b74",
				loader: "spinner"
			});			

			onMounted( async () => {
				if (route.params.proveedor_id) {
					utils.getProveedor(route.params.proveedor_id)
					.then( p => {
						proveedor.value = p
					})

					utils.getProductosPorProveedor(route.params.proveedor_id)
					.then( p => {
						productos.value = p
					})
				} else {
					admin.value = true
					utils.getProductosTodoChido()
					.then( p => {						
						productos.value = p
					})

					utils.getAllProductosNotTodoChido()
					.then( p => {
						allProductos.value = p
					})
				}
			})

			async function addProductoToTodoChido(productoToAdd) {
				if (!productoToAdd) {
					alert('Favor de escoger un producto.')
				}				

				let loader = loading.show()

				utils.updateProductoProp(productoToAdd.id, {todoChido: true})
				.then( () => {
					loader.hide()					
					productos.value.push(productoToAdd)
					producto.value = ""
					useToast().success('Producto actualizado', { position: "top-right", duration: 5000 });	
					
					allProductos.value.splice(allProductos.value.indexOf(productoToAdd), 1);
				})
				.catch( error => {
					console.log(error)
					loader.hide()
					alert('Hubo un error, favor de intentarlo otra vez.')
				})
			}

			function getLink(producto) {
				if (route.name == "Admin_TodoChido") {
					return `/admin/todochido/${producto.id}`
				}

				return `/proveedor/${producto.proveedor}/producto/${producto.id}` 
			}
			
			const filteredProductos = computed( () => {
				return productos.value ? productos.value.filter(p => p.archivado == archivado.value).sort((a,b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0)) : []
			})

			return {      				
				proveedor,
				allProductos,
				productos,
				producto,
				filteredProductos,
				archivado,
				getLink,
				admin,
				addProductoToTodoChido
			}
		},
	}
</script> 