<template>

	<div class="flex flex-col h-full">
		<div class="flex-auto overflow-scroll">

			<h3 class="text-2xl font-bold pt-4 px-4">      		
				Entradas
			</h3>			

			<div v-if="entradas && entradas.length > 0" class="sm:hidden w-full text-left px-4 pb-4">	
				<div class="border boder-gray-300 rounded p-2 mt-4 cursor-pointer" v-for="entrada in entradas" v-bind:key="entrada.id">	
					<router-link :to=' "/admin/entrada/" + entrada.id'>
						<div class="flex flex-col">
							#{{entrada.id}}

							<div class="mt-2">
								<label class="block text-gray-700 text-sm font-bold" for="nombre">
									Proveedor
								</label>
								<div class="flex items-center">
									{{entrada.proveedor.nombre}}
								</div>
							</div> 

							<div class="mt-2 grid grid-cols-2">
								<div>
									<label class="block text-gray-700 text-sm font-bold" for="nombre">
										Fecha
									</label>
									<div class="flex items-center">
										{{entrada.created_at.split("T")[0]}}
									</div>
								</div>								
							</div>

						</div>
					</router-link>
				</div>
			</div>
			
			<div v-if="entradas && entradas.length > 0" class="hidden sm:block w-full text-left px-4 mt-4 pb-4">		
				<div class="border boder-gray-300 border-b-0 rounded overflow-hidden mt-4 bg-white">
					<div class="overflow-scroll">
						<table class="w-full text-sm text-left ">
							<thead class="text-s border-b border-b-gray-200">						
								<th scope="col" class="py-3 px-6">
									Entrada
								</th>
								<th scope="col" class="py-3 px-6">
									Fecha
								</th>
								<th v-if="showAddButton" scope="col" class="py-3 px-6">
									Proveedor
								</th>						
							</thead>
							<tbody v-for="entrada in entradas" v-bind:key="entrada.id" class="text-s border-b">						
								<th scope="row" class="py-3 px-6 font-medium whitespace-nowrap underline cursor-pointer">
									<router-link :to='  route.params.proveedor_id ? `/proveedor/${route.params.proveedor_id}/entrada/${entrada.id}` : "/admin/entrada/" + entrada.id'>
										{{entrada.id}}
									</router-link>
								</th>
								<td class="py-3 px-6 whitespace-nowrap">
									{{entrada.created_at.split("T")[0]}}
								</td>
								<td v-if="showAddButton" class="py-3 px-6 whitespace-nowrap cursor-pointer">									
									{{entrada.proveedor.nombre}}									
								</td>								
							</tbody>
						</table>
					</div>		
				</div>
			</div>
		</div>
		<div v-if="showAddButton" class="p-4 flex-none border-t border-t-gray-300">
			<router-link :to=' "/admin/entrada" '>
				<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4">
					Agregar entrada
				</button>
			</router-link>
		</div>
	</div>
</template>

<script>
	import { supabase } from "../supabase"
	import { onMounted, ref } from "vue"
	import { useRoute } from "vue-router"	

	export default {
		name: 'Order',
		setup() {
			const route = useRoute()
			const entradas = ref(null)		
			const showAddButton = ref(false)

			async function getEntradas() {
				let { data, error, status } = await supabase
				.from("entrada")
				.select('*, proveedor(id, nombre)')   
				.order('created_at', { ascending: false })       						

				if (error && status !== 406) {
					throw error
				}

				if (data) {   								
					if (route.params.proveedor_id) {
						entradas.value = data.filter( d => {
							return d.proveedor.id == route.params.proveedor_id
						})
						return
					} 
					
					entradas.value = data
					return
				} else if (error) {  
					alert(error)     
				}   

				return
			}
			
			onMounted( async () => {
				getEntradas()

				if (!route.params.proveedor_id) {
					showAddButton.value = true
				}
			})

			return {      				
				entradas,
				showAddButton,
				route			
			}
		},
	}
</script> 