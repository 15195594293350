<template>
	<div class="flex flex-col h-full">
		<div class="flex-auto overflow-scroll">
			<h3 class="text-2xl font-bold pt-4 px-4">
				Relación Proveedor Cliente
			</h3>

			<div class="px-4 pt-4" >
				<label class="block text-gray-700 text-sm font-bold mb-2 mt-0" >
					Proveedor
				</label>
				<select v-model="proveedor" v-if="proveedores && proveedores.length > 0" id="countries" class="border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
					<option selected disabled value="">Escoger un proveedor</option>
					<option v-for="proveedor in proveedores" v-bind:key="proveedor.id" :value="proveedor">{{proveedor.nombre}}</option>			
				</select>				
			</div>

			<div class="px-4 pt-4" >
				<label class="block text-gray-700 text-sm font-bold mb-2 mt-0" >
					Cliente
				</label>
				<select v-model="cliente" v-if="clientes && clientes.length > 0" id="countries" class="border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
					<option selected disabled value="">Escoger un cliente</option>
					<option v-for="cliente in clientes" v-bind:key="cliente.id" :value="cliente">{{cliente.nombre}}</option>			
				</select>				
			</div>
		</div>

		<div class="p-4 flex-none border-t border-t-gray-300">
			<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4" @click="createRelacion()">
				Crear relación
			</button>
		</div> 
		
	</div>
</template>

<script>
	import { supabase } from "../supabase"	
	import { onMounted, ref } from "vue"
	import { useToast } from 'vue-toast-notification';
	
	export default {
		name: 'Proveedor Cliente',
		setup() {	
			const cliente = ref("")
			const clientes = ref(null)								
			const proveedor = ref("")
			const proveedores = ref(null)

			async function getClientes() {
				let { data, error, status } = await supabase
				.from("clientes")
				.select('id, nombre')								
				.order('nombre', { ascending: true })				

				if (error && status !== 406) {
					throw error
				}

				if (data) {   
					clientes.value = data
				} else if (error) {  
					alert(error)     
				}   
			}

			async function getProveedores() {
				let { data, error, status } = await supabase
				.from("proveedores")
				.select('id, nombre')								         						
				.order('nombre', { ascending: true })				

				if (error && status !== 406) {
					throw error
				}

				if (data) {   
					proveedores.value = data
					return data
				} else if (error) {  
					alert(error)     
				}   

				return
			}

			async function getProveeorCliente() {
				let { data, error } = await supabase
				.from("cliente_proveedor")
				.select('proveedor, cliente')
				.match({										
					"cliente": cliente.value.id,
					"proveedor": proveedor.value.id,					
				})
				.limit(1)

				if (error) {
					console.log("getProveeorCliente", error)
					alert('Hubo un error')
					return null
				} else if (data && data.length > 0) {	
					console.log(data)				
					return true
				}

				return false
			}

			async function createRelacion() {
				let relacionExiste = await getProveeorCliente()

				if (relacionExiste != false) {
					cliente.value = null
					proveedor.value = null
					return alert('Relación ya existe')
				} 

				let { data, error } = await supabase
				.from('cliente_proveedor')
				.insert({
					cliente: cliente.value.id,
					proveedor: proveedor.value.id,
					"activo": true
				})
				
				if (error) {  		
					console.log("createRelacion", error)			
					alert ("Hubo un error")
				} else if (data) {  
					cliente.value = null
					proveedor.value = null
					useToast().success('Relación creada', { position: "top-right" });
				} 
			}

			onMounted(() => {	
				getProveedores()				
				getClientes()				
			})

			return {     
				cliente,
				clientes,
				proveedor,
				proveedores,
				createRelacion
			}
		},
	}
</script> 