<template>
	<div class="flex flex-col h-full">
		<div class="flex-auto overflow-scroll">
			<h3 class="text-2xl font-bold pt-4 px-4">      	
				Entregas
			</h3>								
			
			<empty v-if="pedidos.filter( p => (!p.entregado || p.entregado && !p.evidencia)).length == 0" :texto="'No tienes entregas pendientes'"> </empty>
			<div v-for="pedido in pedidos.filter( p => (!p.entregado || p.entregado && !p.evidencia))" v-bind:key="pedido.id">					
				<div class="text-sm border boder-gray-300 rounded mt-4 mb-4 mx-4 bg-white">
					<div class="flex justify-between py-4 px-4 items-top">
						<div class="flex-none w-full">
							<div class="flex justify-between w-full">
								<p class="font-bold">
									Pedido #{{pedido.id}}
								</p>
								<p class="">
									{{pedido.created_at ? (new Date(pedido.created_at)).toLocaleDateString("en-GB", { timeZone: 'America/Mexico_City' }) : ""}}
								</p>
							</div>
							<p class="">
								{{pedido.cliente ? pedido.cliente.nombre : ""}}
							</p>
						</div>						
					</div>		

					<div class="text-sm overflow-scroll px-4 py-4 border-t border-b-gray-200">
						<div class="w-full flex justify-between">
							<p class="font-bold">
								Dirección de entrega
							</p>
							<a :href="getMapLink(pedido)" target="_blank" class="underline cursor-pointer">
								<!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
									<path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
									<path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
								</svg> -->
								Abrir mapa

							</a>
						</div>

						<p>{{pedido.datos_entrega.direccion.calle}} {{pedido.datos_entrega.direccion.exterior}}</p>						
						<p>{{pedido.datos_entrega.direccion.colonia}} {{pedido.datos_entrega.direccion.codigo}}</p>						
						<p>{{pedido.datos_entrega.direccion.estado}}</p>
					</div>

					<div v-if="pedido.instrucciones"  class="overflow-scroll">
						<table class="w-full text-sm text-left ">
							<thead class="border-b border-t border-b-gray-200">
								<tr>
									<th scope="col" class="py-3 px-4 w-4/12">
										Instrucciones
									</th>													
								</tr>
							</thead>
							<tbody class="">
								<tr class="text-s">
									<th scope="row" class="py-3 px-4 font-medium whitespace-nowrap">
										{{pedido.instrucciones}}
									</th>														
								</tr>              
							</tbody>
						</table>
					</div>

					<div class="overflow-scroll">
						<table class="w-full text-sm text-left ">
							<thead class=" border-b border-t border-b-gray-200 border-t-gray-200">
								<tr>
									<th scope="col" class="py-3 px-4 w-4/12">
										Nombre
									</th>												
									<th scope="col" class="py-3 px-4 w-4/12">
										Cantidad
									</th>							
								</tr>
							</thead>
							<tbody>
								<tr class=" border-b border-b-gray-200" v-for="line_item in pedido.line_items" v-bind:key="line_item.id">				
									<td class="py-3 px-4 whitespace-nowrap ">
										<div class="flex items-center">
											<div v-if="line_item.producto.foto" class="rounded overflow-hidden w-20 mr-4">
												<img :src="line_item.producto.foto" class="">
											</div>
											{{line_item.producto.nombre}}
										</div>
									</td>									
									<td class="py-3 px-4">
										{{line_item.cantidad}}
									</td>							
								</tr>              
							</tbody>
							<tfoot class="text-gray-700 dark:text-gray-400 ">									
								<tr class="border-b border-b-gray-200">
									<th scope="col" class="py-3 px-4 w-4/12">
										Total
									</th>
									<th v-if="pedido.id" scope="col" class="py-3 px-4 w-4/12">
										{{pedido.line_items.map( l => {return (l.cantidad)} ).reduce((a, b) => a + b) }}
									</th>							
								</tr>
							</tfoot>
						</table>
					</div>

					<div class="p-2 space-y-2">
						<button v-if="!pedido.entregado" class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4" @click="entregarPedido(pedido)">
							Marcar entregado
						</button>	
						<div v-if="pedido.entregado">
							<label class="whitespace-nowrap cursor-pointer inline-block w-full border border-primary hover:bg-primary-dark text-white bg-primary font-bold text-center px-4 py-2 rounded" :for="999">Subir evidencia</label>
							<input
							class="hidden"
							type="file"
							:id="999"
							accept="image/*"
							@change="subirEvidencia(pedido, $event)"		
							/>
						</div>	
					</div>	
				</div>

			</div>	
		</div>
		
	</div>
</template>

<script>	
	import { onMounted, ref } from "vue"
	import { utils } from './utils/utils.js'
	import { useToast } from 'vue-toast-notification';
	import { useLoading } from 'vue-loading-overlay'
	import { useRoute } from 'vue-router'
	
	export default {
		name: 'Entregas',
		setup() {
			const route = useRoute()
			const pedidos = ref([])

			const loading = useLoading({
				color: "#428b74",
				loader: "spinner"
			});

			function entregarPedido(pedido) {
				if(confirm("¿Quieres marcar este pedido como entregado?")){		
					let loader = loading.show()
					updatePedidoWithoutConfirm(pedido, { entregado: true })					
					.then( () => {		
						loader.hide()		
						pedido.entregado = true
						useToast().success('Pedido entregado', { position: "top-right" });
					})
					.catch( error => {					
						loader.hide()
						throw error
					})
				}
			}

			async function updatePedidoWithoutConfirm(pedido, data) {
				console.log(pedido, data)
				let key = Object.keys(data)[0]				
				let loader = loading.show()			

				utils.updatePedido(pedido.id, data)
				.then( () => {
					loader.hide()
					pedido[key] = data[key]
					useToast().success('Orden actualizado', { position: "top-right", duration: 5000 });									
				})
				.catch( () => {
					loader.hide()
					console.log('Hubo un error, favor de intentarlo otra vez.')
				})
			}

			const subirEvidencia = async (pedido, evt) => {
				let loader = loading.show()

				utils.uploadFileHandler("evidencia", evt)
				.then( fileName => {
					return utils.getURLWrapper("evidencia", fileName)
				})
				.then( url => {
					console.log('URL', url)
					console.log("pedido.evidencia", pedido.evidencia)

					if (!pedido.evidencia) {
						pedido.evidencia = [url]
					} else {
						pedido.evidencia.push(url)
					}

					console.log("pedido.evidencia", pedido.evidencia)
					return updatePedidoWithoutConfirm(pedido, {evidencia: pedido.evidencia})
				})
				.then( () => {					
					loader.hide()
					useToast().success('Evidencia subida', { position: "top-right" });											
				})
				.catch( (e) => {
					console.log(e)
					loader.hide()
					alert('Error al subir evidencia, favor de intertarlo otra vez.')
				})
			}	

			function getMapLink(pedido) {
				let de = pedido.datos_entrega.direccion
				let dir = [de.exterior, de.calle, de.colonia, de.codigo]
				console.log(dir)
				let text = encodeURIComponent(dir.join(" "))
				
				console.log(text)

				return `https://www.google.com/maps/search/?api=1&query=${text}`
			}

			onMounted( async () => {
				pedidos.value = await utils.getEntregas(route.params.proveedor_id)
			})			

			return {      				
				pedidos,
				entregarPedido,
				subirEvidencia,
				getMapLink
			}
		},
	}
</script> 