<template>
	<div class="flex flex-col h-full">
		<div class="flex-auto overflow-scroll">
			<div class="pt-4 px-4 mb-4">
				<div class="flex content-center">
					<div @click="utils.goBack(router,'/admin/payouts')" class="pr-2 flex items-center cursor-pointer"> 				
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
							<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
						</svg>
					</div>	
					<h3 class="text-2xl font-bold">
						Payout
					</h3>
				</div>	
			</div>						

		<div class="flex flex-row w-full px-4 gap-4">

			<div class="w-full text-left pb-2">
				<select v-model="month" id="countries" class="border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">		
					<option selected disable :value="null">Escoge el mes</option>											
					<option value="1">Enero</option>
					<option value="2">Febrero</option>
					<option value="3">Marzo</option>
					<option value="4">Abril</option>
					<option value="5">Mayo</option>
					<option value="6">Junio</option>
					<option value="7">Julio</option>
					<option value="8">Agosto</option>
					<option value="9">Septiembre</option>
					<option value="10">Octubre</option>
					<option value="11">Noviembre</option>
					<option value="12">Diciembre</option>
				</select>
			</div>		

			<div class="w-full text-left pb-2">
				<select v-model="year" id="countries" class="border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">		
					<option selected disable value="">Escoge el año</option>					
					<option value="2023">2023</option>				
					<option value="2024">2024</option>					
				</select>
			</div>		
		</div>				

		<div class="w-full text-left px-4 pb-2">
			<select v-model="proveedor" v-if="proveedores.length > 0" id="countries" class="border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">		
				<option selected :value="{nombre: 'Todos'}">Todos</option>						
				<option v-for="proveedor in proveedores" v-bind:key="proveedor.id" :value="proveedor">{{proveedor.nombre}}</option>			
			</select>
		</div>	


		<div  v-if="proveedor.id" class="border boder-gray-300 rounded bg-white m-4">
			<div class="overflow-scroll">
				<table class="w-full text-sm text-left ">
					<thead class="text-s border-b ">						
						<th scope="col" class="py-3 px-6">
							Periodo
						</th>
												
						<th scope="col" class="py-3 px-6">
							Total
						</th>	
						<th scope="col" class="py-3 px-6">
							Comisión
						</th>				
					</thead>
					<tbody>
						<tr class="text-s" >
							<td class="py-3 px-6">																
								{{month}}/{{year}}						
							</td>						
							<td class="py-3 px-6">							
								${{ (filteredPedidos.map( p => {
										return p.line_items.map( li => li.cantidad * li.precio )
									}).reduce((a, b) => a + b) * 100) / 100 }}
							</td>
							<td class="py-3 px-6">							
								{{ proveedor.id ? `$${((filteredPedidos.map( p => {
										return p.line_items.map( li => li.cantidad * li.precio )
									}).reduce((a, b) => a + b) * 100) / 100) * proveedor.pricing.ecom_comision/100 }` : "" }}									
							</td>
						</tr>              
					</tbody>					
				</table>
			</div>
		</div>

		<label class="block text-gray-700 text-sm font-bold pl-4 mt-4">
			Pedidos
		</label>

		<empty v-if="filteredPedidos && filteredPedidos.length == 0" :texto="'No hubo pedidos'"> </empty>

		<div v-if="filteredPedidos && filteredPedidos.length > 0" class="w-full text-left p-4 ">
			<div class="border boder-gray-300 rounded bg-white overflow-hidden">
				<div class="overflow-scroll">
					<table class="w-full text-sm text-left ">
						<thead class="text-s border-b border-b-gray-200">						
							<th scope="col" class="py-3 px-6">
								Pedido
							</th>										
							<th scope="col" class="py-3 px-6">
								Total
							</th>				
							<th scope="col" class="py-3 px-6">
								Comisión
							</th>										
						</thead>
						<tbody>
							<tr class="text-s border-b" v-for="pedido in filteredPedidos" v-bind:key="pedido.id">
								<td class="py-3 px-6 underline">								
									<router-link :to="`/shop/pedido/${pedido.id}`">
										{{pedido.id}}
									</router-link> 										
								</td>
								<td class="py-3 px-6">							
									${{ (pedido.line_items.map( li => li.cantidad * li.precio ).reduce((a, b) => a + b) * 100) / 100 }}
								</td>
								<td class="py-3 px-6">							
									{{ proveedor.id ? `${((pedido.line_items.map( li => li.cantidad * li.precio ).reduce((a, b) => a + b) * 100) / 100) * (proveedor.pricing.ecom_comision / 100)}` : "" }}
								</td>							
							</tr>              
						</tbody>
						<tfoot class="">
							<tr>
								<th scope="col" class="py-3 px-6">
									Total
								</th>
								<th scope="col" class="py-3 px-6">
									${{ (filteredPedidos.map( p => {
										return p.line_items.map( li => li.cantidad * li.precio )
									}).reduce((a, b) => a + b) * 100) / 100 }}
								</th>
								<th scope="col" class="py-3 px-6">
									{{ proveedor.id ? `$${((filteredPedidos.map( p => {
										return p.line_items.map( li => li.cantidad * li.precio )
									}).reduce((a, b) => a + b) * 100) / 100) * (proveedor.pricing.ecom_comision / 100) }` : "" }}									
								</th>									
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
		</div>
	</div>
	<div v-if="proveedor.id && year && month" class="p-4 flex-none border-t border-t-gray-300">							
		<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4" @click="hacerPayout()">
			Hacer payout
		</button>
	</div>
</div>
</template>

<script>
	import { useRoute, useRouter } from 'vue-router'
	import { supabase } from "../supabase"
	import { onMounted, ref, computed } from "vue"
	import { useLoading } from 'vue-loading-overlay'
	import { utils } from './utils/utils.js'		

	const loading = useLoading({
		color: "#428b74",
		loader: "spinner"
	});

	export default {		
		setup() {
			const route = useRoute()
			const router = useRouter()
			const pedidos = ref([])	
			const proveedor = ref({nombre: 'Todos'})
			const proveedores = ref([])														
			const month = ref("1")		
			const year = ref("2024")

			async function getPedidos() {
				let { data, error } = await supabase
				.from("pedidos")
				.select('*, proveedor(*, pricing(*)), line_items(*)')       
				.order('created_at', { ascending: true })
				.eq('ecom', true)						
				.is("payout", null)			

				console.log(data, error)

				console.log(data.filter( e => e.pedido))
				if (data) {              
					return data
				} else if (error) { 
					console.log("getPedidos", error)       
					throw error 
				}   
			} 

			const filteredPedidos = computed( () => {
				if (!month.value || !year.value) {
					return []
				}

				if (!proveedor.value.id) {										
					return pedidos.value.filter( p => p.created_at < getCorte() )
				} else {
					return pedidos.value.filter( p => p.proveedor.id == proveedor.value.id ).filter( p => p.created_at < getCorte() )
				}
			})

			function getCorte() {
				return `${ month.value == "12" ? (Number(year.value) + 1) : year.value }-${ month.value == "12" ? "01" : String((Number(month.value)+1)).padStart(2, '0') }-01T00:00:00.000000+00:00`
			}
			onMounted( async () => {				
				proveedores.value = await utils.getProveedores()
				pedidos.value = await getPedidos()	
			})	
	
			async function hacerPayout() {	
				// if (filteredEnvios.value.filter( e => e.status == "pendiente").length > 0) {
				// 	return alert('Hay envíios pendientes')
				// }

				if (!month.value || !year.value) {
					return alert('Favor de escoger una fecha de corte.')
				}	

				if(confirm("¿Quieres hacer este payout?")){						
					let loader = loading.show()

					//TODO convert to payouts
					// let existing_recibos = await utils.getReciboPorMes(month.value, year.value, proveedor.value.id)

					// if (existing_recibos.length > 0) {
					// 	loader.hide()

					// 	return alert('Un recibo ya existe por este proveedor por este periodo.')
					// }

					let total = (filteredPedidos.value.map( p => { return p.line_items.map( li => li.cantidad * li.precio ) }).reduce((a, b) => a + b) * 100) / 100
					let comision = ((filteredPedidos.value.map( p => { return p.line_items.map( li => li.cantidad * li.precio ) }).reduce((a, b) => a + b) * 100) / 100) * (proveedor.value.pricing.ecom_comision / 100)

					let payout = {
						proveedor: proveedor.value.id,
						mes: Number(month.value),
						año: year.value,
						total: total,
						comision: comision,
						comision_percentage: proveedor.value.pricing.ecom_comision
						
					}

					// console.log('Crear:', recibo)
					// console.log(filteredEnvios.value)

					let data = await createPayout(payout)

					let payout_id = data.id			

					let pedidos_to_update = JSON.parse(JSON.stringify(filteredPedidos.value))	

					// console.log("envios_to_update", envios_to_update)			

					if (pedidos_to_update.length > 0) {				
						await updatePedidos(pedidos_to_update.map( p => {
							p.payout = payout_id		
							p.proveedor = p.proveedor.id
							delete p.line_items
							return p
						}))
					}

					pedidos.value = await getPedidos()	
					proveedor.value = {nombre: 'Todos'}

					loader.hide()
				}
			}

			// function calculateAlmacen() {
			// 	if (!proveedor.value.tarimas) {
			// 		return 0
			// 	} 

			// 	return proveedor.value.tarimas * proveedor.value.pricing.almacen 
			// }

			async function updatePedidos(pedidos_to_update) {
				console.log("pedidos", pedidos_to_update)
				let { data, error } = await supabase
				.from('pedidos')
				.upsert(pedidos_to_update)

				console.log(data, error)
			}

			async function createPayout(payout) {
				let { data, error } = await supabase
				.from('payouts')
				.upsert([payout])
				.single()

				console.log(data, error)
				return data
			}

			return {
				route,
				router,
				pedidos,				
				proveedores,
				filteredPedidos,
				proveedor,
				hacerPayout,
				month,
				year,
				utils
			}
		}
	}
</script>