<template>		
	<div class="border boder-gray-300 rounded mt-4 mx-4 bg-white">
		<p class="p-8 text-center">
			{{texto}}
		</p>
	</div>
</template>

<script>
	export default {
		props: ['texto']
	}
</script>